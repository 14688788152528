<template>
  <section
    id="stats"
    class="py-5"
    data-aos="fade-up"
    v-if="loading == false && stats.countries"
  >
    <div class="col-10 mx-auto justify-content-center">
      <h3 class="text-center">Statistics</h3>
      <div class="row mx-auto justify-content-around">
        <div class="col-12 col-md-3 my-5 text-center">
          <span class="statsNumber"> {{ stats.countries }} </span>
          <span class="statsText"> Countries Served </span>
        </div>

        <div class="col-12 col-md-3 my-5 text-center">
          <span class="statsNumber"> {{ stats.cycles }} </span>
          <span class="statsText"> Annual Test Cycles </span>
        </div>

        <div class="col-12 col-md-3 my-5 text-center">
          <span class="statsNumber"> {{ stats.programs }}+ </span>
          <span class="statsText"> Programs Offered </span>
        </div>
      </div>
    </div>
    <div class="bgImg">
      <img class="parImg" src="../../../assets/img/lab.jpg" alt="" />
    </div>
  </section>
</template>

<script>
import Api from "../../../services";
import simpleParallax from "simple-parallax-js";

export default {
  created() {
    this.getStats();
    this.applyParallax();
  },
  data() {
    return {
      stats: {},
      loading: true,
    };
  },
  methods: {
    applyParallax() {
      const img = document.getElementsByClassName("parImg");
      new simpleParallax(img, { scale: 2.0 });
    },
    getStats() {
      Api.statistics
        .get()
        .then((res) => {
          this.stats = res.data;
          this.loading = false;
          this.$store.commit("setLandingProgress", {
            key: "statistics",
            value: true,
          });
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
