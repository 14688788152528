<script setup>
import { ref } from "vue";

const chatBoxClosed = ref(true);
const msg = ref(null);

const messages = ref([
	{
		text: "Hey there! 👋. \nHow can we help you? Talk to us.",
		type: "incoming",
	},
]);

const toggleChatBox = () => {
	chatBoxClosed.value = !chatBoxClosed.value;
};

const sendMsg = () => {
	var msgBox = document.getElementById("chatBoxArea");
	if (msgBox.innerText !== "") {
		msg.value = msgBox.innerText;
		messages.value.push({
			text: msgBox.innerText,
			type: "outgoing",
		});

		msgBox.innerText = "";

		setTimeout(() => {
			messages.value.push({
				text: "Thank you for reaching out! We will get back to you as soon as possible. 😊",
				type: "incoming",
			});
		}, 1000);

		setTimeout(() => {
			window.open(
				`https://wa.me/254115854474?text=${encodeURIComponent(
					msg.value
				)}`
			);
		}, 2000);
	}
};
</script>

<template>
	<div id="chatWidget">
		<div id="chatBoxWrap" :class="`${chatBoxClosed ? 'closed' : ''}`">
			<div id="chatBox">
				<div id="chatBoxHeader">
					<div id="chatAvatar">
						<div id="chatAvatarImg">
							<img src="../../assets/img/logo.png" alt="" />
						</div>
						<div id="chatAvatarInfo">
							<p>HuQAS</p>
							<span>Online</span>
						</div>
					</div>
				</div>
				<div id="chatBoxContent">
					<div
						id="chatBoxItem"
						v-for="(msg, index) in messages"
						:key="index"
						:class="msg.type"
					>
						<p>{{ msg.text }}</p>
					</div>
				</div>
				<div id="chatBoxFooter">
					<div id="chatBoxField">
						<p id="chatBoxArea" contenteditable="true"></p>
					</div>
					<div id="chatBoxButton" @click="sendMsg">
						<span><i class="fas fa-paper-plane"></i></span>
					</div>
				</div>
			</div>
		</div>
		<div
			id="chatButtonWrap"
			:class="`${chatBoxClosed ? 'closed' : ''}`"
			@click="toggleChatBox"
		>
			<div id="chatButton">
				<i class="fab fa-whatsapp"></i>
			</div>
		</div>
	</div>
</template>
