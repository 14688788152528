<template>
  <div :class="`inputGroup mb-5 ${this.class}`">
    <label for="">{{ this.label }}</label>
    <input
      type="text"
      class="text-input"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="this.placeholder"
      :required="this.required"
    />
  </div>
</template>

<script>
export default {
  props: ["label", "modelValue", "placeholder", "required", "type", "class"],
  emits: ["update:modelValue"],
};
</script>
