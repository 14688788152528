<template>
  <div class="row justify-content-around slide">
    <div class="slide-info">
      <h1>{{ data?.title }}</h1>
      <h3 v-if="data.type !== 'programs'">
        {{ getDate(data?.start_date, data?.end_date) }}
      </h3>
      <p v-html="truncatedDescription"></p>

      <router-link
        :to="'/training/' + data?.slug"
        v-if="data.type == 'training'"
      >
        <button class="primary">Register</button>
      </router-link>

      <router-link
        :to="`/conference/${data.slug}`"
        v-if="data.type == 'conference'"
      >
        <button class="primary">Read More...</button>
      </router-link>

      <router-link to="/programs" v-if="data.type == 'programs'">
        <button class="primary">Enroll Now</button>
      </router-link>

      <router-link :to="data.url" v-if="data.type == 'custom'">
        <button class="primary">{{ data.cta }}</button>
      </router-link>
    </div>
    <div class="slide-shadow"></div>
    <div class="slide-img">
      <img :src="data?.visual.url" alt="" />
    </div>
  </div>
</template>

<script>
import getDateRange from "../../../../helpers/dates";

export default {
  props: ["data", "type"],
  methods: {
    getDate(start_date, end_date) {
      return getDateRange(start_date, end_date);
    },
  },
  computed: {
    truncatedDescription() {
      if (this.data.description.length > 250) {
        return this.data.description.substring(0, 250) + "...";
      } else {
        return this.data.description;
      }
    },
  },
};
</script>
