const count = (start) => {
  var d = new Date();
  var f = new Date(start);

  var diff = f.getTime() - d.getTime();

  var timer = {
    days: Math.floor(diff / (1000 * 60 * 60 * 24)),
    hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((diff / (1000 * 60)) % 60),
    seconds: Math.floor(diff / 1000) % 60,
  };

  return timer;
};

export default count;
