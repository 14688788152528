<template>
  <section
    class="carousel d-flex justify-content-center"
    v-if="!this.$store.loading"
  >
    <div class="row justify-content-around slide">
      <div class="slide-info">
        <div v-if="firstNews">
          <h1>{{ firstNews.title }}</h1>
          <h3>{{ getDates }}</h3>
          <p>{{ firstNews.description }}</p>
        </div>
        <router-link v-bind:to="'/news/article/' + firstNews.slug">
          <button class="primary">Read More</button>
        </router-link>
      </div>
      <div class="slide-shadow"></div>
      <div class="slide-img">
        <div class="carousel-img-wrap">
          <img
            :src="firstNews.thumbnail.url"
            alt=""
            v-if="firstNews.thumbnail"
          />
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="row">
      <h3 class="py-3">Recent Posts</h3>
      <div class="col-12 col-sm-10 col-md-8 pb-5" v-if="this.news.length > 1">
        <NewsComponent
          v-for="article in this.truncatedNews"
          v-bind:key="article.id"
          v-bind:article="article"
        ></NewsComponent>
      </div>
    </div>
  </section>
</template>

<script>
import NewsComponent from "./newsComponent.vue";
import Api from "../../services";
import { formatDate } from "../../helpers/dates";

export default {
  components: {
    NewsComponent,
  },
  created() {
    this.getNews();
  },
  data() {
    return {
      news: [],
    };
  },
  computed: {
    getDates() {
      return formatDate(this.firstNews.date);
    },
    firstNews() {
      if (this.news.length >= 1) {
        return this.news[0];
      }
      return false;
    },
    truncatedNews() {
      var truncated = [...this.news];
      truncated.splice(0, 1);
      return truncated;
    },
  },
  methods: {
    getNews() {
      this.$store.commit("setLoading", true);
      Api.news
        .get(`?_sort=id:asc&_limit=10`)
        .then(
          (res) => (this.news = res.data),
          this.$store.commit("setLoading", false)
        )
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
