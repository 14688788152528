<template>
  <section class="carousel d-flex justify-content-center">
    <div class="row justify-content-around slide">
      <div class="slide-info">
        <div>
          <h1>HuQAS EQA Programs</h1>
          <p>
            HuQAS offers Proficiency Testing Programs that are relevant to
            medical laboratories’ area of operation e.g. Clinical Chemistry,
            Blood Screening, Hematology, Parasitology, Bacteriology and so much
            more.
          </p>
        </div>
        <router-link to="/programs/quotation/programs">
          <button class="primary">Request For Quote</button>
        </router-link>
      </div>
      <div class="slide-shadow"></div>
      <div class="slide-img">
        <img
          src="https://huqasstrapimedia.s3.us-east-2.amazonaws.com/20220315_123708_114a7160e1.jpg"
          alt=""
        />
      </div>
    </div>
  </section>
</template>
