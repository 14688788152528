<template>
	<section class="row justify-content-between" v-if="!this.loading">
		<div class="col-11 col-sm-10 col-lg-7" v-if="this.training.title">
			<h2>{{ training.title }}</h2>
			<div class="ImageWrap">
				<img v-if="training.visual" :src="training.visual.url" alt="" />
			</div>
			<div class="mt-4">
				<span class="">
					<span> <i class="fas fa-calendar-alt"></i> </span>
					{{ getDates }}
				</span>
				<span class="mx-3" v-if="training.venue">
					<span> <i class="fas fa-map-marker-alt"></i></span>
					{{ training.venue }}
				</span>
				<span class="mx-3" v-if="!training.venue">
					<span> <i class="fas fa-map-marker-alt"></i></span>
					HuQAS Training Center
				</span>
				<span class="mx-3">
					<span> <i class="fas fa-users"></i> </span>
					{{ training.available_slots }} Slots
				</span>
				<div class="my-3" v-if="training.charges">
					<!-- Charges: -->
					<div v-html="training?.charges"></div>
				</div>
			</div>
			<div class="my-3" v-if="this.isPast">
				<button class="secondary" @click="form.toggleModal">
					Register
				</button>
			</div>
			<!-- <div class="mt-5">
        <p>
          {{ training.description }}
        </p>
      </div> -->

			<article v-html="training.about"></article>
			<Gallery />
		</div>
		<div class="col-11 col-sm-10 col-lg-4">
			<h3 class="m-0">Other Trainings</h3>
			<div class="d-flex flex-wrap py-3">
				<TrainingComponent
					v-for="training in this.trainings"
					v-bind:key="training.id"
					v-bind:training="training"
				/>
			</div>
		</div>
	</section>
	<!-- <TrainingForm v-bind:training="training.id"></TrainingForm> -->
	<RegistrationForm :form="form" />
</template>

<script>
import Gallery from "../../components/gallery";
import getDateRange, { getToday } from "../../helpers/dates";
import Api from "../../services";
// import TrainingForm from "./trainingForm.vue";
import TrainingComponent from "./trainingComponent";
import RegistrationForm from "../../components/form/form.vue";

export default {
	components: { Gallery, TrainingComponent, RegistrationForm },
	props: ["params"],
	mounted() {
		this.getData();
		this.getTrainings();
	},
	data() {
		return {
			training: {},
			trainings: [],
			gallery: [],
			loading: false,
			form: {
				showingModal: false,
				loading: false,
				toggleModal: this.toggleModal,
				handleSubmit: this.handleSubmit,
				submitted: false,
			},
		};
	},
	watch: {
		params() {
			this.getData();
		},
	},
	methods: {
		getData() {
			this.loading = true;
			Api.training
				.get(`?slug=${this.params.slug}`)
				.then((res) => {
					this.training = res.data[0];
					this.getGallery(res.data[0].id);
					this.loading = false;
				})
				.catch((e) => {
					console.log(e);
					this.loading = false;
				});
		},
		getTrainings() {
			this.$store.commit("setLoading", true);
			var now = getToday();
			Api.training
				.get(`?end_date_gt=${now}&_sort=start_date:ASC`)
				.then((res) => {
					this.trainings = res.data;
				}, this.$store.commit("setLoading", false))
				.catch((error) => {
					console.log(error);
				});
		},
		getGallery(id) {
			Api.trainingGalleries.get(`?training=${id}`).then((res) => {
				this.gallery = res.data;
			});
		},
		showTrainingForm() {
			let modal = document.getElementById("registrationFormModal");
			modal.style.display = "block";
		},
		toggleModal() {
			this.form.showingModal = !this.form.showingModal;
		},
		sendemail(data) {
			Api.sendemail.post({ ...data, template: "training" });
		},
		handleSubmit(e, data) {
			e.preventDefault();
			this.form.loading = !this.form.loading;

			Api.trainingRegistration
				.post({ ...data, training: this.training.id })
				.then((res) => {
					console.log(res.data);
					this.sendemail(res.data);
					this.form.submitted = !this.form.submitted;
					this.form.loading = !this.form.loading;
				})
				.catch((err) => {
					this.form.loading = !this.form.loading;
					console.log(err);
				});
		},
	},
	computed: {
		getDates() {
			return getDateRange(
				this.training.start_date,
				this.training.end_date
			);
		},
		isPast() {
			var now = new Date();
			var end_date = new Date(this.training.end_date);

			if (end_date - now > 0) {
				return true;
			} else {
				return false;
			}
		},
	},
};
</script>
