<template>
  <div class="text-center">
    <p>Select the programs you're interested in.</p>
  </div>
  <div id="programsForm">
    <div
      v-for="program in programs"
      class="programRow"
      v-bind:key="program.id"
      v-bind:id="`programRow-` + program.id"
    >
      <div class="firstRow">
        <div class="check">
          <span>
            <i
              class="far fa-square"
              v-bind:id="`checkIcon-` + program.id"
              v-on:click="
                this.$store.commit('quotations/addProgram', program.id)
              "
            ></i>
          </span>
        </div>
        <div
          class="d-flex justify-content-between w-100"
          v-on:click="showAnalytes(program.id)"
        >
          <div class="programName">
            <p>{{ program.name }}</p>
          </div>
          <div class="dropdown">
            <span>
              <i
                class="fas fa-caret-down"
                v-bind:id="`dropicon-` + program.id"
                v-bind:data-program="program.id"
              ></i>
            </span>
          </div>
        </div>
      </div>
      <div
        class="lastRow"
        v-bind:id="'lastRow-' + program.id"
        data-showing="off"
      >
        <p>4 test events * {{ program.samples }} samples in 4 shipments.</p>
        <h4>Analytes</h4>
        <ol>
          <li
            v-for="analyte in program.program_analytes"
            v-bind:key="analyte.name"
          >
            {{ analyte.name }}
          </li>
        </ol>
      </div>
    </div>
  </div>

  <div class="inputGroup text-center my-5">
    <p v-if="this.errors.length >= 1">{{ this.errors[0] }}</p>
    <button class="secondary" id="submitBtn" v-on:click="addPrograms()">
      Next
      <span>
        <i class="fas fa-arrow-right"></i>
      </span>
    </button>
  </div>
</template>

<script>
import Api from "../../../services";

export default {
  components: [],
  created() {
    this.getPrograms();
  },
  data() {
    return {
      programs: [],
      quotation: [],
      errors: [],
    };
  },
  methods: {
    showAnalytes(id) {
      var list = document.getElementById(`lastRow-${id}`);
      var icon = document.getElementById(`dropicon-${id}`);
      if (list.dataset.showing === "off") {
        list.style.display = "block";
        list.style.height = "auto";
        list.dataset.showing = "on";
        icon.classList.remove("fa-caret-down");
        icon.classList.add("fa-caret-up");
      } else {
        list.style.display = "none";
        list.style.height = "0px";
        list.dataset.showing = "off";
        icon.classList.remove("fa-caret-up");
        icon.classList.add("fa-caret-down");
      }
    },

    getPrograms() {
      Api.programs.get().then((res) => (this.programs = res.data));
    },
    sendEmail(data) {
      Api.sendemail.post({ ...data, template: "eqa" });
    },
    AddProgram(id) {
      var arr = this.quotation;
      var row = document.getElementById(`programRow-${id}`);
      var icon = document.getElementById(`checkIcon-${id}`);
      if (arr.includes(id)) {
        arr.splice(arr.indexOf(id), 1);
        this.quotation = arr;
        row.classList.remove("rowSelected");
        icon.classList.remove("fa-check-square");
        icon.classList.add("fa-square");
      } else {
        arr.push(id);
        this.quotation = arr;
        row.classList.add("rowSelected");
        icon.classList.remove("fa-square");
        icon.classList.add("fa-check-square");
      }
    },
    addPrograms() {
      if (this.$store.getters["quotations/programsSelected"]) {
        this.$router.push("/programs/quotation/details");
      } else {
        this.errors.push("Please Select at least one program.");
        setTimeout(() => {
          this.errors = [];
        }, 6000);
        var obj = JSON.parse(sessionStorage.getItem("quotation"));
        var btn = document.getElementById("submitBtn");
        // btn.innerHTML = "Sending &nbsp; <i class='fas fa-spinner fa-spin'></i>";
        obj.programs = this.quotation;
        Api.quotations.post(obj).then((res) => {
          this.sendEmail(res.data);
          btn.innerHTML = "Sent!";
          btn.disabled = "disabled";
          sessionStorage.removeItem("quotation");
          this.quotation = [];
        });
      }
    },
  },
};
</script>

<style lang="scss">
.lastRow {
  transition: 0.3s ease-in-out all;
  ol {
    padding-left: 15px;
    line-height: 26px;
  }
}
.rowSelected {
  background-color: #ebd4c2;
}
</style>
