<template>
	<div class="modalContainer" id="registrationFormModal">
		<div class="modal col-11 col-md-8 col-lg-6 py-3">
			<span class="close modalClose" @click="closeModal">
				<i class="fas fa-times"></i>
			</span>
			<div class="text-center">
				<h2 class="mb-0">Conference Registration</h2>
				<div class="w-50 mx-auto">
					<p>
						For Presentations, please use the Submit abstracts form.
					</p>
				</div>
			</div>
			<div v-if="!formSent">
				<form @submit="handleForm($event)" id="quotationForm">
					<div class="inputGroup halfGroup mb-5">
						<label for="">Organization Name</label>
						<input
							type="text"
							class="text-input"
							v-model="user.organization"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Position/Title</label>
						<input
							type="text"
							class="text-input"
							v-model="user.title"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">First Name</label>
						<input
							type="text"
							class="text-input"
							v-model="user.first_name"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Last Name</label>
						<input
							type="text"
							class="text-input"
							v-model="user.last_name"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Email Address</label>
						<input
							type="text"
							class="text-input"
							v-model="user.email"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Phone number</label>
						<input
							type="text"
							class="text-input"
							v-model="user.phone_number"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Town/City:</label>
						<input
							type="text"
							class="text-input"
							v-model="user.town"
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Country</label>
						<input
							type="text"
							class="text-input"
							v-model="this.user.country"
							required
						/>
					</div>
					<div class="inputGroup mb-5">
						<label for="">Fees: </label>
						<div class="checkboxGroup">
							<input
								type="radio"
								name="is_member"
								id="member"
								value="true"
								@change="handleMembership"
								required
							/>
							<label for="member">
								HuQAS/MeLSAT Members: KES 7,550 / USD 60
							</label>
						</div>
						<div class="checkboxGroup">
							<input
								type="radio"
								name="is_member"
								id="non_member"
								value="false"
								@change="handleMembership"
								required
							/>
							<label for="non_member">
								Non HUQAS/MeLSAT Participants: KES 8,750 / USD 70	</label
							>
						</div>
					</div>

					<div class="inputGroup text-center">
						<button class="secondary" id="submitBtn">
							Send &nbsp;
							<i class="fas fa-paper-plane"></i>
						</button>
					</div>
				</form>
			</div>
			<div v-if="formSent" class="text-center">
				<h3 class="text-center">Your Spot has been Reserved!</h3>
				<span>
					<i class="fas fa-check-double fa-8x"></i>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import Api from "../../../services";

export default {
	props: ["conference"],
	data() {
		return {
			user: {
				organization: "",
				title: "",
				first_name: "",
				last_name: "",
				email: "",
				phone_number: "",
				town: "",
				country: "",
				conference: null,
				is_member: "",
			},
			formSent: false,
		};
	},
	methods: {
		closeModal() {
			var modal = document.getElementById("registrationFormModal");
			modal.style.display = "None";
		},
		updateConference() {
			this.user.conference = this.conference;
		},
		validateForm() {
			if (
				this.user.first_name &&
				this.user.last_name &&
				this.user.email
			) {
				this.user.email = this.user.email.trim();
				return true;
			} else {
				return false;
			}
		},
		loadingBtn(str) {
			var btn = document.getElementById("submitBtn");
			if (btn) {
				btn.innerHTML = str;
			}
		},
		sendEmail(data) {
			Api.sendemail.post({ ...data, template: "conference" }).then(() => {
				Api.sendemail.post({
					...data,
					template: "conferenceConfirmation",
				});
			});
		},
		resetForm() {
			this.user = {
				organization: "",
				first_name: "",
				last_name: "",
				email: "",
				phone_number: "",
				town: "",
				country: "",
				conference: null,
				is_member: "",
			};
		},
		handleMembership(e) {
			this.user.is_member = e.target.value;
		},
		handleForm(e) {
			e.preventDefault();
			this.updateConference();
			this.loadingBtn(
				"Sending... <i class='fas fa-circle-notch fa-spin'></i>"
			);
			if (this.validateForm()) {
				Api.conferenceRegistration
					.post(this.user)
					.then((res) => {
						this.formSent = true;
						this.loadingBtn("Sent! <i class='fas fa-check'></i>");
						this.sendEmail(res.data);
						setTimeout(() => {
							this.closeModal();
							this.formSent = false;
							this.resetForm();
							this.loadingBtn(
								"Send <i class='fas fa-paper-plane></i>'"
							);
						}, 5000);
					})
					.catch((error) => {
						console.log(typeof error);
						this.loadingBtn(
							`${error.message} <i class='fas fa-exclamation-triangle'></i>`
						);
					});
			}
		},
	},
};
</script>
