const mkMetaElement = (property, content) => {
  if(!document.querySelector(`meta[property="${property}]"`)){
    let el = document.createElement("meta");
    el.setAttribute("property", property);
    el.content = content;
    document.getElementsByTagName("head")[0].appendChild(el);
  } else {
    document.querySelector(`meta[property="${property}]"`).setAttribute("content", content)
  }
}

export const AddMetaTags = (obj) => {
  mkMetaElement("og:url", window.location.href)
  mkMetaElement("og:type", "Website")
  mkMetaElement("og:title", `${obj.title} | HuQAS`)
  mkMetaElement("og:image", obj.image)
  mkMetaElement("og:description", obj.description)
  
  if(obj.title){
    document.title = `${obj.title} | HuQAS`;
  }

  if(obj.description){
    document.querySelector('meta[name="description"]').setAttribute("content", obj.description)
  }
};