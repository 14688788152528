import Api from "../../../services";

const quotationStore = {
  state: {
    userDetails: {},
    programs: [],
    posted: false,
  },
  getters: {
    programsSelected: (state) => {
      return state.programs.length >= 1;
    },
  },
  mutations: {
    addProgram(state, id) {
      var arr = [...state.programs];
      var row = document.getElementById(`programRow-${id}`);
      var icon = document.getElementById(`checkIcon-${id}`);
      if (arr.includes(id)) {
        arr.splice(arr.indexOf(id), 1);
        row.classList.remove("rowSelected");
        icon.classList.remove("fa-check-square");
        icon.classList.add("fa-square");
      } else {
        arr.push(id);
        row.classList.add("rowSelected");
        icon.classList.remove("fa-square");
        icon.classList.add("fa-check-square");
      }

      state.programs = arr;
    },
    addUserDetails(state, obj) {
      state.userDetails = { ...obj };
    },
    clearPrograms(state) {
      state.programs = [];
    },
    setPosted(state, n) {
      state.posted = n;
    },
  },
  actions: {
    postQuotation(context) {
      var payload = {
        ...context.state.userDetails,
        programs: context.state.programs,
      };
      Api.quotations.post(payload).then((res) => {
        context.commit("clearPrograms");
        context.commit("addUserDetails", {});
        context.commit("setPosted", true);
        Api.sendemail.post({ ...res.data, template: "eqa" });
        return true;
      });
    },
  },
  namespaced: true,
};

export default quotationStore;
