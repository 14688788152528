<template>
  <section class="py-5">
    <div class="row m-0">
      <div class="col-12 text-center">
        <h2>HuQAS PT DESIGN</h2>
      </div>
      <div class="col-12">
        <div class="col-12 col-md-8 col-lg-6 mx-auto my-5">
          <img src="../../assets/img/pt_design.svg" alt="" class="w-100" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
