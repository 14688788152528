<template>
	<section class="row" v-if="this.loading === false">
		<div class="py-2 col-10 col-md-8 mx-auto">
			<div>
				<div class="videoPlayer" v-if="abstract.videourl">
					<iframe
						width="100%"
						height="100%"
						top="0"
						:src="`https://youtube.com/embed/${abstract.videourl}`"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
				<h2>
					{{ abstract.title }}
				</h2>
				<div class="my-2">
					<i class="fas fa-users"></i>&nbsp;
					<span v-html="getAuthors.authors"></span>
				</div>
				<div class="my-2">
					<i class="fas fa-building"></i>&nbsp;
					<span v-html="getAuthors.affiliations"></span>
				</div>
			</div>
		</div>
	</section>

	<section class="row pb-5">
		<article
			class="col-10 col-lg-8 mx-auto"
			v-html="abstract.article"
		></article>

		<div class="my-5">
			<div class="mx-auto col-10 col-lg-8">
				<div id="disqus_thread"></div>
			</div>
		</div>
	</section>
</template>

<script>
import Api from "../../services";
import { disqus } from "../../services/disqus";

export default {
	components: {},
	props: ["params"],
	created() {
		this.getAbstract();
		disqus();
	},
	data() {
		return {
			abstract: {},
			loading: true,
			authors: "",
			organizations: [],
			affiliations: "",
		};
	},
	methods: {
		getAbstract() {
			Api.abstracts
				.get(`?slug=${this.$route.params.slug}`)
				.then((res) => {
					this.abstract = res.data[0];
					this.loading = false;
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
	computed: {
		getAuthors() {
			var mainAuthor = this.abstract.authors[0];

			if (this.abstract.authors.length > 0) {
				this.abstract.authors.forEach((author, index) => {
					if (this.organizations.includes(author.organization)) {
						null;
					} else {
						this.organizations.push(author.organization);
					}
					this.authors += `${author.first_name} ${
						author.last_name
					}<sup>${
						this.organizations.indexOf(author.organization) + 1
					}</sup> ${
						index == this.abstract.authors.length - 1 ? "" : ","
					} `;
				});

				this.organizations.forEach((org, index) => {
					this.affiliations += `${org}<sup>${index + 1}</sup> ${
						index == this.organizations.length - 1 ? "" : ","
					} `;
				});

				return {
					authors: this.authors,
					affiliations: this.affiliations,
				};
			} else {
				return `${mainAuthor.first_name} ${mainAuthor.last_name} <sup>1</sup>`;
			}
		},
	},
};
</script>

<style scoped>
.videoPlayer {
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	padding-top: 30px;
	overflow: hidden;
	position: relative;
	background-color: #eee;
	border-radius: 30px;
}
.videoPlayer iframe,
.videoPlayer object,
.videoPlayer embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
