<template>
	<div :class="`inputGroup2 ${this.class}`">
		<label for="">{{ this.label }}</label>
		<input
			:type="this.type"
			class="text-input"
			:id="this.id"
			:value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
			:placeholder="this.placeholder"
			:required="this.required"
			:pattern="this.pattern"
			:accept="this.accept"
		/>
	</div>
</template>

<script>
export default {
	props: [
		"label",
		"modelValue",
		"placeholder",
		"required",
		"type",
		"class",
		"pattern",
		"accept",
		"id",
	],
	emits: ["update:modelValue"],
};
</script>
