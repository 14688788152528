<template>
	<section
		class="carousel mx-auto"
		v-if="headlines.length >= 0"
		data-aos="fade-up"
	>
		<splide :options="options" v-if="isLoading === false">
			<splide-slide v-for="headline in headlines" :key="headline.id">
				<slide :data="headline"></slide>
			</splide-slide>
		</splide>
	</section>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import Slide from "./carousel-slide.vue";
import Api from "../../../../services";
import { getToday } from "../../../../helpers/dates";

export default {
	components: { Splide, SplideSlide, Slide },
	created() {
		this.getConference();
		this.getTraining();
		this.getHeadlines();
	},
	data() {
		return {
			headlines: [],
			options: {
				rewind: true,
				perPage: 1,
				autoplay: true,
				interval: 3000,
			},
			isLoading: true,
		};
	},
	methods: {
		getTraining() {
			var now = getToday();
			Api.training
				.get(`?end_date_gt=${now}&_sort=start_date:ASC`)
				.then((res) => {
					if (res.data[0]) {
						this.headlines.push({
							...res.data[0],
							type: "training",
						});
					}
				})
				.catch((e) => {
					console.log(e.message);
				});
		},
		getConference() {
			var today = getToday();
			Api.conferences
				.get(`?_sort=start_date:desc&end_date_gt=${today}`)
				.then((res) => {
					if (res.data[0]) {
						this.headlines.push({
							...res.data[0],
							type: "conference",
						});
					}
				})
				.catch((e) => {
					console.log(e.message);
				});
		},
		getHeadlines() {
			Api.headlines
				.get()
				.then((res) => {
					if (res.data[0]) {
						this.headlines.push({
							...res.data[0],
							type: "programs",
						});
						this.isLoading = false;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
</script>
