<template>
  <div
    class="section calendar-section"
    data-aos="fade-up"
    v-if="events.length > 0"
  >
    <div class="col-12">
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-row align-items-center">
          <h3 class="text-center">Calendar of Events</h3>
        </div>
      </div>
      <splide :options="options">
        <splide-slide
          v-for="ev in events_ints"
          v-bind:key="ev"
          class="calendar-slide"
        >
          <event-component :events="events" :ev="ev" />
        </splide-slide>
      </splide>
    </div>
  </div>
</template>

<script>
import Api from "../../services/index";
import getDateRange, { getToday } from "../../helpers/dates";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import EventComponent from "./eventComponent.vue";

export default {
  components: { Splide, SplideSlide, EventComponent },
  created() {
    this.getEvents();
  },
  data() {
    return {
      events_ints: [],
      events: [],
      options: {
        rewind: true,
        perPage: 4,
        perMove: 1,
        breakpoints: {
          1150: {
            perPage: 3,
          },
          900: {
            perPage: 2,
          },
          600: {
            perPage: 1,
          },
        },
        autoplay: true,
        interval: 3000,
        pagination: false,
      },
    };
  },
  methods: {
    getEvents() {
      var now = getToday();
      Api.events
        .get(`?end_date_gt=${now}&_sort=start_date:asc`)
        .then((res) => {
          res.data.forEach((ev, index) => {
            this.events.push({
              ...ev,
              dates: getDateRange(ev.start_date, ev.end_date),
            });
            this.events_ints.push(index);
          });
          this.$store.commit("setLandingProgress", {
            key: "calendar",
            value: true,
          });
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
  },
};
</script>
