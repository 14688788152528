<template>
  <div class="text-center">
    <p>Please fill in your details.</p>
  </div>
  <form @submit="handleForm($event)" id="quotationForm">
    <text-input
      v-model="user.organization"
      label="Organization"
      placeholder="Enter your place of work"
      required="true"
      class="halfGroup"
    />

    <text-input
      v-model="user.title"
      placeholder="Title/Position"
      required="true"
      class="halfGroup"
      label="Title/Position"
    />

    <text-input
      v-model="user.first_name"
      label="First Name"
      required="true"
      class="halfGroup"
    />

    <text-input
      v-model="user.last_name"
      label="Last Name"
      required="true"
      class="halfGroup"
    />

    <text-input
      v-model="user.email"
      label="Email Address"
      required="true"
      class="halfGroup"
    />

    <text-input
      v-model="user.phone_number"
      label="Phone Number"
      required="true"
      class="halfGroup"
    />

    <text-input
      v-model="user.town"
      label="Town"
      required="true"
      class="halfGroup"
    />

    <text-input
      v-model="user.country"
      label="Country"
      required="true"
      class="halfGroup"
    />

    <div class="inputGroup text-center">
      <button class="secondary">
        Send Request &nbsp;
        <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </form>
</template>

<script>
import textInput from "../../../components/inputs/textInput";
export default {
  components: { textInput },
  created() {
    this.loadSessionData();
  },
  data() {
    return {
      user: {
        organization: "",
        title: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        town: "",
        country: "",
        type: "eqa",
      },
    };
  },
  methods: {
    handleForm(e) {
      e.preventDefault();
      this.$store.commit("quotations/addUserDetails", this.user);
      this.$store.dispatch("quotations/postQuotation").then(() => {
        this.$router.push("/programs/quotation/success");
      });
    },
    loadSessionData() {
      if (sessionStorage.getItem("quotation")) {
        this.user = JSON.parse(sessionStorage.getItem("quotation"));
      }
    },
  },
};
</script>
