<template>
  <div class="row m-0">
    <div class="col-12">
      <div id="loading">
        <div id="loadingWrap">
          <div class="loading-logo">
            <img src="../assets/img/logo.svg" alt="" />
          </div>
          <div className="loading-progress-bar">
            <div
              class="progress-indicator"
              :style="'width:' + progress + '%'"
            ></div>
          </div>
        </div>
      </div>
      <p>Loading. Please Wait...</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["progress"],
};
</script>

<style lang="scss">
#loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  #loadingWrap {
    @keyframes logoloading {
      0% {
        opacity: 1;
      }
      25% {
        opacity: 0.5;
      }
      50% {
        opacity: 0.3;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
    .loading-logo {
      img {
        width: 200px;
        animation-name: logoloading;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      margin: 10px auto;
    }
    .loading-progress-bar {
      height: 5px;
      width: 200px;
      border-radius: 10px;
      background-color: #ccc;
      display: block;
      overflow: hidden;
      .progress-indicator {
        height: 100%;
        position: relative;
        width: 0%;
        background: #9061a7;
      }
    }
  }
}
</style>
