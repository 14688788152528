<template>
	<div class="modalContainer" id="abstractFormModal">
		<div class="modal col-11 col-md-8 col-lg-6 py-3">
			<span class="close modalClose" @click="closeModal">
				<i class="fas fa-times"></i>
			</span>
			<div class="text-center">
				<h2>Submit Abstract</h2>
			</div>
			<div v-if="!formSent">
				<form
					@submit="handleForm($event)"
					id="quotationForm"
					enctype="multipart/form-data"
				>
					<div class="inputGroup halfGroup mb-5">
						<label for="">Organization Name</label>
						<input
							type="text"
							class="text-input"
							v-model="user.organization"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Position/Title</label>
						<input
							type="text"
							class="text-input"
							v-model="user.title"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">First Name</label>
						<input
							type="text"
							class="text-input"
							v-model="user.first_name"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Last Name</label>
						<input
							type="text"
							class="text-input"
							v-model="user.last_name"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Email Address</label>
						<input
							type="text"
							class="text-input"
							v-model="user.email"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Phone number</label>
						<input
							type="text"
							class="text-input"
							v-model="user.phone_number"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Town/City:</label>
						<input
							type="text"
							class="text-input"
							v-model="user.town"
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Country</label>
						<input
							type="text"
							class="text-input"
							v-model="this.user.country"
							required
						/>
					</div>

					<div class="inputGroup mb-5">
						<label for="">Abstract File</label>
						<input
							type="file"
							class="file-input"
							id="abstract_file"
							accept=".pdf,.doc,docx"
							@change="handleFile"
							required
						/>
					</div>

					<div class="inputGroup">
						<span>
							<h4>Fees:</h4>
							<p><b>Presenters: </b> USD 60 / KES 7,550</p>
						</span>
					</div>

					<div class="inputGroup text-center">
						<button class="secondary" id="abstractsSubmitBtn">
							Send &nbsp;
							<i class="fas fa-paper-plane"></i>
						</button>
					</div>
				</form>
			</div>
			<div v-if="formSent" class="text-center">
				<h3 class="text-center">Your Spot has been Reserved!</h3>
				<span>
					<i class="fas fa-check-double fa-8x"></i>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import Api from "../../../services";

export default {
	props: ["conference"],
	data() {
		return {
			user: {
				organization: "",
				title: "",
				first_name: "",
				last_name: "",
				email: "",
				phone_number: "",
				town: "",
				country: "",
				conference: null,
				is_presenter: true,
			},
			file: {},
			formSent: false,
		};
	},
	methods: {
		closeModal() {
			var modal = document.getElementById("abstractFormModal");
			modal.style.display = "None";
		},
		validateForm() {
			if (
				this.user.first_name &&
				this.user.last_name &&
				this.user.email
			) {
				return true;
			} else {
				return false;
			}
		},
		updateConference() {
			this.user.conference = this.conference;
		},
		loadingBtn(str) {
			var btn = document.getElementById("abstractsSubmitBtn");
			btn.innerHTML = str;
		},
		resetForm() {
			this.user = {
				organization: "",
				first_name: "",
				last_name: "",
				email: "",
				phone_number: "",
				town: "",
				country: "",
				conference: null,
				is_presenter: true,
			};
			this.file = {};
		},
		makePayload() {
			let fd = new FormData();
			fd.append("data", JSON.stringify(this.user));
			fd.append("files.abstract_file", this.file);
			return fd;
		},
		handleFile(e) {
			this.file = e.target.files[0];
		},
		sendEmail(data) {
			Api.sendemail.post({ ...data, template: "conference" }).then(() => {
				Api.sendemail.post({
					...data,
					template: "conferenceConfirmation",
				});
			});
		},
		handleForm(e) {
			e.preventDefault();
			this.updateConference();
			this.loadingBtn(
				"Sending... <i class='fas fa-circle-notch fa-spin'></i>"
			);
			if (this.validateForm()) {
				Api.conferenceRegistration
					.upload(this.makePayload())
					.then((res) => {
						this.formSent = true;
						this.sendEmail(res.data);
						this.loadingBtn("Sent! <i class='fas fa-check'></i>");
						setTimeout(() => {
							this.formSent = false;
							this.resetForm();
							this.closeModal();
						}, 3000);
					})
					.catch((error) => {
						console.log(typeof error);
						this.loadingBtn(
							`${error.message} <i class='fas fa-exclamation-triangle'></i>`
						);
					});
			}
		},
	},
};
</script>
