<template>
  <div class="col-12">
    <form @submit="sendForm($event)">
      <div class="inputGroup mb-5">
        <label for="">Organization Name</label>
        <input type="text" class="text-input" v-model="user.organization" />
      </div>

      <div class="inputGroup halfGroup mb-5">
        <label for="">First Name</label>
        <input
          type="text"
          class="text-input"
          v-model="user.first_name"
          required
        />
      </div>

      <div class="inputGroup halfGroup mb-5">
        <label for="">Last Name</label>
        <input type="text" class="text-input" v-model="user.last_name" />
      </div>

      <div class="inputGroup halfGroup mb-5">
        <label for="">Email Address</label>
        <input type="text" class="text-input" v-model="user.email" required />
      </div>

      <div class="inputGroup halfGroup mb-5">
        <label for="">Phone number</label>
        <input type="text" class="text-input" v-model="user.phone_number" />
      </div>

      <div class="inputGroup mb-5">
        <label for="">Subject</label>
        <input type="text" class="text-input" v-model="user.subject" required />
      </div>

      <div class="inputGroup mb-5">
        <label for="formMessage">Message</label>
        <textarea
          id="formMessage"
          class="textarea"
          v-model="user.message"
          required
        >
        </textarea>
      </div>

      <div class="inputGroup text-center">
        <button class="primary" id="submitBtn">
          <span v-if="!loading && !sent"
            >Send &nbsp; <i class="fas fa-paper-plane"></i
          ></span>
          <span v-if="loading"
            >Sending...&nbsp;<i class="fas fa-circle-notch fa-spin"></i
          ></span>
          <span v-if="sent">Sent!</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Api from "../../services";

export default {
  data() {
    return {
      user: {},
      loading: false,
      sent: false,
    };
  },
  methods: {
    sendForm(e) {
      e.preventDefault();
      console.log(this.user);
      this.loading = true;
      Api.contactform
        .post(this.user)
        .then(() => {
          this.loading = false;
          this.sent = true;
          setTimeout(() => {
            this.sent = false;
            this.user = {};
          }, 5000);
        })
        .catch((error) => {
          console.log(error.message);
          this.sent = false;
        });
    },
  },
};
</script>
