<template>
  <section class="gallery py-5" v-if="gallery?.length > 0">
    <div>
      <h2 class="text-center">Gallery</h2>
    </div>
    <div class="row m-0 justify-content-center">
      <div class="col-10 col-md-6">
        <div class="gallery-slider">
          <splide
            :slides="gallery"
            :options="options"
            @splide:mounted="onMounted"
            @splide:moved="moved"
          >
            <splide-slide v-for="slide in gallery" :key="slide.id">
              <div class="gallery-slide">
                <img v-if="slide.image" :src="slide.image.url" alt="" />
                <img v-if="slide.visual" :src="slide.visual.url" alt="" />
              </div>
            </splide-slide>
          </splide>
        </div>
      </div>
      <div class="col-10 col-md-4 d-flex align-items-end flex-column">
        <div class="caption">
          <p>
            {{ this.gallery[this.currIndex].caption }}
          </p>
        </div>
        <div>
          <p id="slider-controls" style="font-style: italic">
            <!-- <span id="prev-btn">
              <i class="fas fa-angle-left"></i>
            </span> -->
            {{ currIndex + 1 }} of {{ totalSlides }}
            <!-- <span id="next-btn">
              <i class="fas fa-angle-right"></i>
            </span> -->
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  props: ["gallery"],
  components: { Splide, SplideSlide },
  created() {},
  data() {
    return {
      options: {
        rewind: true,
        perPage: 1,
        autoplay: true,
        interval: 3000,
        // arrows: false,
      },
      currIndex: 0,
      currCaption: "",
    };
  },
  methods: {
    moved(splide) {
      this.currIndex = splide.index;
    },
    onMounted(splide) {
      this.currIndex = splide.index;
    },
  },
  computed: {
    totalSlides() {
      return this.gallery.length;
    },
  },
};
</script>
