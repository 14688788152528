<template>
	<div class="modalContainer" id="sponsorRegistrationFormModal">
		<div class="modal col-11 col-md-8 col-lg-6 py-3">
			<span class="close modalClose" @click="closeModal">
				<i class="fas fa-times"></i>
			</span>
			<div class="text-center">
				<h2>Become a Conference Sponsor</h2>
				<span>
					<p>
						<a
							target="_blank"
							href="https://drive.google.com/file/d/1tDbuHG01DMwuwvJSr8lG8Bp3N5vvLF8V/view?usp=sharing"
							>Click here to view our sponsorship packages.</a
						>
					</p>
				</span>
			</div>
			<div v-if="!formSent">
				<form @submit="handleForm($event)" id="quotationForm">
					<div class="inputGroup halfGroup mb-5">
						<label for="">Organization Name</label>
						<input
							type="text"
							class="text-input"
							v-model="user.organization"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Title/Position</label>
						<input
							type="text"
							class="text-input"
							v-model="user.title"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">First Name</label>
						<input
							type="text"
							class="text-input"
							v-model="user.first_name"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Last Name</label>
						<input
							type="text"
							class="text-input"
							v-model="user.last_name"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Email Address</label>
						<input
							type="text"
							class="text-input"
							v-model="user.email"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Phone number</label>
						<input
							type="text"
							class="text-input"
							v-model="user.phone_number"
							required
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Town/City:</label>
						<input
							type="text"
							class="text-input"
							v-model="user.town"
						/>
					</div>

					<div class="inputGroup halfGroup mb-5">
						<label for="">Country</label>
						<input
							type="text"
							class="text-input"
							v-model="this.user.country"
							required
						/>
					</div>

					<div class="inputGroup mb-5">
						<label for="">Packages:</label>
						<div
							class="checkboxGroup"
							v-for="pkg in packages"
							:key="pkg.id"
						>
							<input
								type="radio"
								name="package"
								v-bind:id="pkg.name"
								v-bind:value="pkg.id"
								@change="handlePackages"
								required
							/>
							<label :for="pkg.name">{{
								pkg.name + ` - USD ` + pkg.amount
							}}</label>
						</div>
					</div>

					<div id="errors">
						<p class="text-center error" id="error"></p>
					</div>

					<div class="inputGroup text-center">
						<button class="secondary" id="sponsorsSubmitBtn">
							Send &nbsp;
							<i class="fas fa-paper-plane"></i>
						</button>
					</div>
				</form>
			</div>
			<div v-if="formSent" class="text-center">
				<h3 class="text-center">Your Spot has been Reserved!</h3>
				<span>
					<i class="fas fa-check-double fa-8x"></i>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import Api from "../../../services";

export default {
	props: ["conference"],
	created() {
		this.getPackages();
	},
	data() {
		return {
			user: {
				organization: "",
				title: "",
				first_name: "",
				last_name: "",
				email: "",
				phone_number: "",
				town: "",
				country: "",
				conference: null,
				sponsor_packages: null,
				is_sponsor: true,
			},
			packages: [],
			formSent: false,
		};
	},
	methods: {
		closeModal() {
			var modal = document.getElementById("sponsorRegistrationFormModal");
			modal.style.display = "None";
		},
		updateConference() {
			this.user.conference = this.conference;
		},
		getPackages() {
			Api.packages
				.get("?_sort=id:asc")
				.then((res) => (this.packages = res.data))
				.catch((e) => console.log(e));
		},
		handlePackages(e) {
			this.user.sponsor_packages = e.target.value;
		},
		validateForm() {
			if (
				this.user.first_name &&
				this.user.last_name &&
				this.user.email &&
				this.user.sponsor_packages
			) {
				return true;
			} else {
				return false;
			}
		},
		resetForm() {
			this.user = {
				organization: "",
				first_name: "",
				last_name: "",
				email: "",
				phone_number: "",
				town: "",
				country: "",
				conference: null,
				sponsor_packages: null,
				is_sponsor: true,
			};
		},
		loadingBtn(str) {
			var btn = document.getElementById("sponsorsSubmitBtn");
			btn.innerHTML = str;
		},
		showErrors() {
			let errDiv = document.getElementById("error");
			errDiv.innerText = "Please fill in all the fields.";
		},
		sendEmail(data) {
			Api.sendemail.post({ ...data, template: "conference" });
		},
		handleForm(e) {
			e.preventDefault();
			this.updateConference();
			this.loadingBtn(
				"Sending... <i class='fas fa-circle-notch fa-spin'></i>"
			);
			if (this.validateForm()) {
				Api.conferenceRegistration
					.post(this.user)
					.then((res) => {
						this.formSent = true;
						this.resetForm();
						this.sendEmail(res.data);
						this.loadingBtn("Sent! <i class='fas fa-check'></i>");
						setTimeout(() => {
							this.closeModal();
							this.formSent = false;
						}, 5000);
					})
					.catch((error) => {
						console.log(typeof error);
						this.loadingBtn(
							`${error.message} <i class='fas fa-exclamation-triangle'></i>`
						);
					});
			} else {
				this.showErrors();
			}
		},
	},
};
</script>
