<template>
  <section id="quotationSection">
    <div class="row m-0">
      <div class="col-12">
        <h3 class="text-center">Request For Quote</h3>
        <div class="progress_bars">
          <span
            v-bind:class="isActive('programs') ? 'sectionActive' : ''"
          ></span>

          <span
            v-bind:class="isActive('details') ? 'sectionActive' : ''"
          ></span>
        </div>
      </div>

      <div class="col-12">
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["path"],
  created() {
    console.log(this.path);
  },
  methods: {
    isActive(str) {
      if (this.path.split("/")[3] === str) {
        return true;
      }
      return false;
    },
  },
};
</script>
