<script setup>
const props = defineProps({
	form: Object,
});

import { reactive } from "vue";
import TextInput from "../../components/inputs/index.vue";
// import Api from "../../services";

// const showingModal = ref(false);

const data = reactive({
	first_name: "",
	last_name: "",
	email: "",
	phone_number: "",
	organization: "",
	title: "",
	town: "",
	country: "",
});

// const toggleModal = () => {
// 	showingModal.value = !showingModal.value;
// };

// const handleSubmit = (e) => {
// 	e.preventDefault();
// 	loading.value = !loading.value;

// 	Api[props.endpoint]
// 		.post(data)
// 		.then((res) => {
// 			console.log(res.data);
// 			submitted.value = !submitted.value;
// 			loading.value = !loading.value;
// 		})
// 		.catch((err) => {
// 			loading.value = !loading.value;
// 			console.log(err);
// 		});
// };

// defineExpose({ toggleModal });
</script>

<template>
	<div v-if="props.form.showingModal">
		<div id="workshopFormContainer">
			<div
				id="closeModal"
				class="cursor-pointer"
				@click="props.form.toggleModal()"
			>
				<i class="far fa-times"></i>
			</div>
			<div id="bgBlur"></div>
			<div id="formContainer" class="open">
				<div v-if="!props.form.submitted && !props.form.loading">
					<h3>Registration</h3>
					<h5>
						Please fill in the form below to register. Fields with
						an asterisk (*) are required.
					</h5>
					<form @submit="(e) => props.form.handleSubmit(e, data)">
						<TextInput
							label="First Name *"
							type="text"
							v-model="data.first_name"
							placeholder="First name"
							class="halfGroup"
							required
						/>

						<TextInput
							label="Last Name *"
							type="text"
							v-model="data.last_name"
							placeholder="Last name"
							class="halfGroup"
							required
						/>

						<TextInput
							label="Email Address *"
							type="email"
							v-model="data.email"
							placeholder="Email Address"
							required
						/>

						<TextInput
							label="Phone Number *"
							type="tel"
							v-model="data.phone_number"
							placeholder="Phone Number"
							min="10"
							required
						/>

						<hr />

						<TextInput
							label="Organization *"
							type="text"
							v-model="data.organization"
							placeholder="Your organization"
							class="halfGroup"
							required
						/>

						<TextInput
							label="Position *"
							type="text"
							v-model="data.title"
							placeholder="Position/Title"
							class="halfGroup"
							required
						/>

						<TextInput
							label="Town"
							type="text"
							v-model="data.town"
							placeholder="Your first name"
							class="halfGroup"
						/>

						<TextInput
							label="Country"
							type="text"
							v-model="data.country"
							placeholder="Country"
							class="halfGroup"
						/>
						<hr />
						<button type="submit" class="btn2 primary">
							Register
						</button>
					</form>
				</div>

				<div
					id="form_success"
					v-if="props.form.submitted && !props.form.loading"
				>
					<div>
						<span class="text-center">
							<i class="far fa-check-circle fa-5x"></i>
						</span>
						<h3>Registration Successful!</h3>
						<router-link to="/">
							<button class="btn2 primary">Go Home</button>
						</router-link>
					</div>
					<div class="form_success_socials">
						<h4>Follow us on our socials.</h4>

						<div>
							<span>
								<a
									target="_blank"
									href="https://www.linkedin.com/company/huqas/"
								>
									<i class="fab fa-linkedin"></i>
								</a>
							</span>
							<span>
								<a
									target="_blank"
									href="https://www.facebook.com/huqas"
								>
									<i class="fab fa-facebook"></i>
								</a>
							</span>
							<span>
								<a
									target="_blank"
									href="https://www.youtube.com/@huqas_eqa"
								>
									<i class="fab fa-youtube"></i>
								</a>
							</span>
							<span>
								<a
									target="_blank"
									href="https://www.twitter.com/huqas"
								>
									<i class="fab fa-twitter"></i>
								</a>
							</span>
						</div>
					</div>
				</div>

				<div id="form_loading" v-if="props.form.loading">
					<span>
						<i class="fas fa-spinner fa-5x fa-spin"></i>
					</span>
					<h4>Sending...</h4>
				</div>
			</div>
		</div>
	</div>
</template>
