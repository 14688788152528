const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getDateRange = (start_date, end_date) => {
  var start = new Date(start_date);
  var end = new Date(end_date);

  var start_month = months[start.getMonth()];
  var end_month = months[end.getMonth()];

  if (start_month === end_month) {
    return `${start.getDate()} - ${end.getDate()} ${
      months[end.getMonth()]
    }, ${end.getFullYear()}`;
  } else {
    return `${start.getDate()} ${start_month} - ${end.getDate()} ${
      months[end.getMonth()]
    }, ${end.getFullYear()}`;
  }
};

export const formatDate = (datestr) => {
  var date = new Date(datestr);
  var month = months[date.getMonth()];
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};

export const formatTime = (datestr) => {
  var date = new Date(datestr);
  return `${date.getHours()}:` + `${date.getUTCMinutes()}`.padStart(2, "0");
};

export const getToday = () => {
  var today = new Date();
  var day = today.getDate().toString().padStart(2, "0");
  var year = today.getFullYear();
  var month = today.getMonth() + 1;
  month = month.toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export default getDateRange;
