<template>
  <div
    class="post mb-5"
    @click="this.$router.push(`/news/article/${article.slug}/`)"
  >
    <div class="postImage">
      <img :src="article.thumbnail.url" alt="" />
    </div>
    <div class="postText">
      <h4 class="m-0">
        {{ article.title }}
      </h4>
      <span class="postDates">
        <h4>{{ getDates }}</h4>
      </span>
      <p class="my-2">{{ truncated(article.description, 200) }}</p>
    </div>
  </div>
</template>
<!-- <router-link v-bind:to="'/news/article/' + article.slug"></router-link> -->
<script>
import { formatDate } from "../../helpers/dates";
import { truncate } from "../../helpers/truncate";

export default {
  props: ["article"],
  methods: {
    truncated(str, int) {
      return truncate(str, int);
    },
  },
  computed: {
    getDates() {
      return formatDate(this.article.date);
    },
  },
};
</script>
