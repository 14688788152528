<template>
  <ProgramsHeadline />
  <programs />
  <calendar />
  <ptdesign />
</template>

<script>
import ProgramsHeadline from "./components/programsHeadline";
import Programs from "./programs.vue";
import Ptdesign from "./ptdesign.vue";
import Calendar from "../../components/calendar";

export default {
  components: {
    ProgramsHeadline,
    Programs,
    Ptdesign,
    Calendar,
  },
};
</script>
