<template>
  <div class="row">
    <div class="col-12">
      <div id="error-page-wrap">
        <div id="error-page">
          <div id="error-icon">
            <i class="fas fa-exclamation-triangle fa-10x"></i>
          </div>
          <h2>Oops!</h2>
          <br />
          <p>It seems like a problem on our side. Please try again later.</p>
        </div>
      </div>
    </div>
  </div>
</template>
