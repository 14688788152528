import { createRouter, createWebHistory } from "vue-router";
import About from "./pages/about";
import Conference from "./pages/conference";
import ConferenceRoutes from "./pages/conference/routes";
import ContactUs from "./pages/contacts";
import Eqa from "./pages/eqa";
import DetailsForm from "./pages/eqa/quotation/detailsForm";
import ProgramsForm from "./pages/eqa/quotation/programsForm";
import Quotation from "./pages/eqa/quotation/quotation";
import Success from "./pages/eqa/quotation/success";
import Error404 from "./pages/errors/error_404.vue";
import Home from "./pages/home";
import Landing from "./pages/home/landing.vue";
import Mentorship from "./pages/mentorship";
import MentorshipQuote from "./pages/mentorship/quotationForm";
import News from "./pages/news";
import NewsArticle from "./pages/news/newsArticle";
import Training from "./pages/training";
import TrainingDetails from "./pages/training/trainingDetails";
import Workshop from "./pages/workshop";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		children: [
			{
				path: "/",
				name: "Landing",
				component: Landing,
			},
			{
				path: "/programs",
				name: "Programs",
				component: Eqa,
				meta: {
					title: "Programs",
				},
			},
			{
				path: "/programs/quotation",
				name: "Quotation",
				component: Quotation,
				props: (route) => ({ path: route.path }),
				children: [
					{
						path: "details",
						component: DetailsForm,
					},
					{
						path: "programs",
						component: ProgramsForm,
					},
					{
						path: "success",
						component: Success,
					},
				],
				meta: {
					title: "Quotation",
				},
			},
			{
				path: "/conference",
				name: "Conference",
				component: Conference,
				children: ConferenceRoutes,
				meta: {
					title: "Conference",
				},
			},
			{
				path: "/training",
				name: "Training",
				component: Training,
				meta: {
					title: "CPD Trainings",
					metaTags: [
						{
							name: "description",
							content:
								"HuQAS offers specialized laboratory centered training that is aimed towards improving lab personel and lab testing.",
						},
						{
							property: "og:description",
							content:
								"HuQAS offers specialized laboratory centered training that is aimed towards improving lab personel and lab testing.",
						},
					],
				},
			},
			{
				path: "/training/:slug",
				name: "Training_Details",
				component: TrainingDetails,
				props: (route) => ({ params: route.params }),
				meta: {
					title: "Training Details",
				},
			},
			{
				path: "/mentorship",
				name: "Mentorship",
				component: Mentorship,
				meta: {
					title: "Mentorship",
				},
			},
			{
				path: "/mentorship/quote",
				name: "Mentorship Quote",
				component: MentorshipQuote,
				meta: {
					title: "Mentorship Quote",
				},
			},
			{
				path: "/news",
				name: "News",
				component: News,
				meta: {
					title: "News",
				},
			},
			{
				path: "/news/article/:slug",
				name: "News Article",
				component: NewsArticle,
				props: (route) => ({ params: route.params }),
				meta: {
					title: "News Article",
				},
			},
			{
				path: "/about",
				name: "About",
				component: About,
				meta: {
					title: "About Us",
				},
			},
			{
				path: "/contact-us",
				name: "Contact Us",
				component: ContactUs,
				meta: {
					title: "Contact Us",
				},
			},
		],
		meta: {
			title: "Home",
		},
	},
	{
		path: "/workshop/tz",
		name: "Workshop",
		component: Workshop,
		meta: {
			title: "Tanzania Workshop",
		},
	},
	{
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		component: Error404,
		meta: {
			title: "Not Found",
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	scrollBehavior() {
		return { top: 0 };
	},
	routes,
});

router.beforeEach((to, from, next) => {
	document.title =
		to.meta.title + " | " + "Human Quality Assessment Services (HuQAS)";
	next();
});

export default router;
