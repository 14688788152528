<template>
  <section class="carousel d-flex justify-content-center">
    <div class="row justify-content-around slide" v-if="firstTraining">
      <div class="slide-info">
        <div>
          <h1>{{ firstTraining.title }}</h1>
          <h3>{{ getDates }}</h3>
          <p>{{ truncated(firstTraining.description) }}</p>
        </div>
        <router-link :to="`/training/${firstTraining.slug}/`">
          <button class="primary">Register</button>
        </router-link>
      </div>
      <div class="slide-shadow"></div>
      <div class="slide-img">
        <img :src="firstTraining.visual.url" alt="" />
      </div>
    </div>
  </section>

  <div v-if="trainings.length > 1">
    <h2 class="text-center">Upcoming Trainings</h2>
    <div class="d-flex flex-wrap justify-content-around py-3">
      <TrainingComponent
        v-for="training in this.otherTrainings"
        v-bind:key="training.id"
        v-bind:training="training"
      ></TrainingComponent>
    </div>
  </div>

  <div v-if="pastTrainings.length > 0">
    <h2 class="text-center">Past Trainings</h2>
    <div class="d-flex flex-wrap justify-content-around py-3">
      <TrainingComponent
        v-for="training in this.pastTrainings"
        v-bind:key="training.id"
        v-bind:training="training"
      ></TrainingComponent>
    </div>
  </div>

  <Calendar></Calendar>
</template>

<script>
import Calendar from "../../components/calendar";
import getDateRange, { getToday } from "../../helpers/dates";
import Api from "../../services";
import TrainingComponent from "./trainingComponent";

export default {
  components: {
    TrainingComponent,
    Calendar,
  },
  created() {
    this.getTrainings();
    this.getPastTrainings();
  },
  data() {
    return {
      trainings: [],
      pastTrainings: [],
    };
  },
  computed: {
    getDates() {
      return getDateRange(
        this.firstTraining.start_date,
        this.firstTraining.end_date
      );
    },
    firstTraining() {
      return this.trainings[0];
    },
    otherTrainings() {
      var arr = [...this.trainings];
      arr.shift();
      return arr;
    },
  },
  methods: {
    getTrainings() {
      this.$store.commit("setLoading", true);
      var now = getToday();
      Api.training
        .get(`?end_date_gt=${now}&_sort=start_date:ASC`)
        .then((res) => {
          this.trainings = res.data;
        }, this.$store.commit("setLoading", false))
        .catch((error) => {
          console.log(error);
        });
    },
    getPastTrainings() {
      this.$store.commit("setLoading", true);
      var now = getToday();
      Api.training
        .get(`?end_date_lt=${now}&_sort=start_date:ASC`)
        .then((res) => {
          this.pastTrainings = res.data;
        }, this.$store.commit("setLoading", false))
        .catch((error) => {
          console.log(error);
        });
    },
    truncated(str) {
      if (str.length > 1) {
        return str.slice(0, 180) + " ...";
      }
      return "";
    },
  },
};
</script>
