import { createApp } from "vue";
import App from "./App.vue";
import Router from "./router";
import "bootstrap/scss/bootstrap-grid.scss";
import "bootstrap/scss/bootstrap-utilities.scss";
import "@splidejs/splide/dist/css/splide.min.css";
import "./assets/scss/main.scss";
import Store from "./store";
import { createHead, VueHeadMixin } from "@unhead/vue";
const head = createHead();

createApp(App)
	.use(Router)
	.mixin(VueHeadMixin)
	.use(head)
	.use(Store)
	.mount("#app");
