<template>
  <div class="row">
    <div class="col-12">
      <div id="error-page-wrap">
        <div id="error-page">
          <div id="error-icon">
            <i class="fas fa-exclamation-triangle fa-10x"></i>
          </div>
          <h2>Sorry, Page not found.</h2>
          <br />
          <router-link to="/">
            <button>Go Back Home</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
