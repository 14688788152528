<template>
  <div class="row col-10 py-5">
    <div class="col-11 text-center">
      <h2>Past Conferences</h2>
    </div>

    <div class="row flex flex-wrap justify-content-between">
      <div
        v-for="conference in conferences"
        :key="conference.id"
        class="post col-12 col-sm-6 col-md-5 mb-5 p-0"
      >
        <div class="postImage">
          <img :src="conference.visual.url" v-if="conference.visual" alt="" />
        </div>

        <div class="postText">
          <router-link :to="`/conference/${conference.slug}`">
            <h3>{{ conference.title }}</h3>
          </router-link>

          <span class="postDates">
            <p>
              {{ conference.description }}
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  props: ["conferences"],
  data() {
    return {};
  },
  methods: {},
};
</script>
