<template>
  <section id="downloads">
    <div class="downloadsWrap">
      <div class="downloadsImg" data-aos="fade-right">
        <img
          v-if="document.visual !== undefined"
          v-bind:src="document.visual.url"
          alt=""
        />
      </div>
      <div class="downloadsText" data-aos="fade-left">
        <h2>{{ document.year }} HuQAS Catalogue</h2>
        <p v-html="document.description" />
        <a
          v-if="document.file !== undefined"
          target="_blank"
          rel="noreferrer"
          :href="document.file[0].url"
          download="2021 HuQAS Catalogue"
        >
          <button class="secondary mt-5">
            Download
            <span class="fas fa-download"></span>
          </button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import Api from "../services";
export default {
  props: ["name"],
  created() {
    Api.catalogues.get("?_sort=year:desc").then((res) => {
      this.document = res.data[0];
      this.$store.commit("setLandingProgress", {
        key: "downloads",
        value: true,
      });
    });
  },
  data() {
    return {
      document: {},
    };
  },
};
</script>
