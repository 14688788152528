import axios from "axios";

class Call {
  apiUrl = process.env.VUE_APP_API_URL;
  mailUrl = process.env.VUE_APP_MAIL_URL;

  constructor(endpoint, mail = false, auth = false) {
    this.auth = auth;
    mail === true
      ? (this.endpoint = `${this.mailUrl}${endpoint}/`)
      : (this.endpoint = `${this.apiUrl}${endpoint}/`);
  }

  getHeaders = () => {
    var token = process.env.VUE_APP_MAIL_TOKEN;
    if (token) {
      var headers = {
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    return headers;
  };

  get = async (params) => {
    return await axios({
      method: "get",
      url: this.endpoint + `${params ? params : ""}`,
    });
  };

  post = async (params) => {
    return await axios({
      method: "post",
      url: this.endpoint,
      data: params,
      headers: this.auth ? this.getHeaders() : {},
    });
  };

  upload = async (params) => {
    return await axios({
      method: "post",
      url: this.endpoint,
      data: params,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  put = async (id, params) => {
    return await axios({
      method: "put",
      url: this.endpoint + "" + id,
      data: params,
      headers: this.auth ? this.getHeaders() : {},
    });
  };

  delete = async (params) => {
    return await axios({
      method: "delete",
      url: this.endpoint + `${params ? params : ""}`,
      headers: this.auth ? this.getHeaders() : {},
    });
  };
}

export default Call;
