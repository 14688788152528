<template>
	<section class="row col-11 mx-auto justify-content-around">
		<div v-if="conference.title">
			<div class="col-11 mx-auto">
				<h1 class="mb-2 mt-4">{{ conference.title }}</h1>
				<div class="d-flex mb-5 justify-center">
					<div class="mx-3">
						<span> <i class="fas fa-calendar-alt"></i> </span>
						{{ getDates }}
					</div>
					<div class="mx-3">
						<span> <i class="fas fa-map-marker-alt"></i></span>
						{{ conference.venue }}
					</div>
				</div>
				<div class="conference-hero">
					<img v-bind:src="conference.visual?.url" alt="" />
				</div>
			</div>

			<div class="mt-5">
				<span v-if="this.isPast">
					<div class="row col-11 col-md-8">
						<div
							class="
								col-11 col-md-4
								mx-auto
								d-flex
								justify-content-center
							"
						>
							<span class="mx-3 my-3">
								<button
									class="secondary"
									@click="showRegistrationFormModal"
								>
									Register
								</button>
							</span>
						</div>
						<div
							class="
								col-11 col-md-4
								mx-auto
								d-flex
								justify-content-center
							"
						>
							<span class="mx-3 my-3">
								<button
									class="tertiary"
									@click="showSponsorsRegistrationFormModal"
								>
									Become a Sponsor
								</button>
							</span>
						</div>
						<div
							class="
								col-11 col-md-4
								mx-auto
								d-flex
								justify-content-center
							"
						>
							<span class="mx-3 my-3">
								<button
									class="secondary"
									@click="showAbstractForm"
								>
									Submit an Abstract
								</button>
							</span>
						</div>
					</div>
				</span>
			</div>
		</div>
	</section>

	<section
		class="row col-11 mx-auto justify-content-around"
		v-if="this.isPast"
	>
		<div class="col-11">
			<article class="mx-auto" v-html="conference.about"></article>
		</div>
	</section>

	<div
		class="row col-11 mx-auto justify-content-around py-5"
		v-if="this.isPast"
	>
		<div class="col-11 col-md-6">
			<h2>Write & Submit Your Own Abstract</h2>
			<p class="col-11">
				Prepare and submit an abstract for consideration in the upcoming
				HuQAS Scientific Conference. Pick a subtheme from the list below
				as a subject for your abstract. We look forward to your
				submission.

				<br />
				<br />

				<a
					target="_blank"
					href="https://drive.google.com/file/d/1jmT8ohAifBIiMN3zea71Lqb3_oIUyaOj/view?usp=sharing"
				>
					Click here to download the Abstract Writing Guidelines
				</a>
			</p>

			<h3>Sub themes</h3>
			<div
				v-html="conference.subthemes"
				class="subthemes text-left"
			></div>

			<button class="secondary my-4" @click="showAbstractForm">
				Submit Your Abstract &nbsp; <i class="fas fa-file-alt"></i>
			</button>
		</div>
		<div class="col-11 col-md-6 d-flex justify-content-center">
			<div
				class="
					position-relative
					w-100
					d-flex
					justify-content-center
					align-items-center
				"
			>
				<div class="pt-5">
					<img
						src="https://huqasstrapimedia.s3.us-east-2.amazonaws.com/abstracts_dd88b62372.png"
						alt=""
						class="w-100"
					/>
				</div>
			</div>
		</div>
	</div>

	<section class="row" v-if="conference?.conference_guests && isPast">
		<div class="col-12 text-center" v-if="guests">
			<h2>Guest Speakers</h2>
		</div>

		<div class="row d-flex justify-content-around py-3" v-if="guests">
			<div
				class="speaker col-12 col-md-6 my-4"
				v-for="speaker in conference.conference_guests"
				:key="speaker.name"
			>
				<div class="">
					<div class="text-center">
						<img v-bind:src="speaker.avatar?.url" alt="" />
					</div>
				</div>
				<div class="text-center">
					<h3 class="m-0">{{ speaker.name }}</h3>
					<p class="m-0 w-100">
						{{ speaker.title }}, <br />
						{{ speaker.organization }}
					</p>
				</div>
			</div>
		</div>
	</section>

	<section class="row py-5" v-if="conference.conference_venue && isPast">
		<div class="col-11 text-center">
			<h2>Venue</h2>
		</div>
		<div class="col-11 mx-auto">
			<div class="row justify-content-center">
				<div class="col-12 col-md-6">
					<div class="venueImages">
						<div class="gallery-slider">
							<splide
								:slides="conference.conference_venue.images"
								:options="options"
								@splide:mounted="onMounted"
								@splide:moved="moved"
							>
								<splide-slide
									v-for="slide in conference.conference_venue
										.images"
									:key="slide.id"
								>
									<div class="gallery-slide">
										<img :src="slide.url" alt="" />
									</div>
								</splide-slide>
							</splide>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="venue-info">
						<h3 class="m-0">
							{{ conference.conference_venue.name }}
						</h3>
						<p>
							{{ conference.conference_venue.city }},
							{{ conference.conference_venue.country }}
						</p>
						<p>
							Get accommodation at discounted rates when you
							attend the conference! Use the discount code
							<b>HUQAS23rd</b> when making your booking at
							<span
								><a href="sm3@africamuthuhotelsmgm.com"
									>sm3@africamuthuhotelsmgm.com</a
								></span
							>
						</p>
						<p>
							<span
								><i class="fas fa-map-marker-alt"></i>
								<a
									target="_blank"
									v-bind:href="
										conference.conference_venue.map_url
									"
								>
									Click to view Map</a
								></span
							>
						</p>
						<br />
						<a
							target="_blank"
							href="https://www.muthuhotelsmgm.com/muthu-nyali-beach-hotel-spa-mombasa-kenya"
						>
							<button class="tertiary">Book Accomodation</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section
		v-if="this.isPast && conference.conference_sponsors.length > 0"
		class="row py-5"
	>
		<div class="col-12 text-center">
			<h2>Conference Sponsors</h2>
		</div>
		<div class="col-12 pt-5">
			<div class="row flex-wrap justify-content-between">
				<div
					class="col-12 col-md-4 col-lg-3 sponsorCard my-3 mx-auto"
					v-for="sponsor in conference.conference_sponsors"
					v-bind:key="sponsor.id"
				>
					<a target="_blank" v-bind:href="sponsor.weblink">
						<div class="sponsorImg">
							<img :src="sponsor.logo?.url" alt="Sponsor Logo" />
						</div>
						<div class="sponsorInfo">
							<h4 class="text-center">{{ sponsor.name }}</h4>
						</div>
					</a>
				</div>
			</div>
		</div>
	</section>

	<section class="row py-5" v-if="1 == 2">
		<div class="col-12 text-center">
			<h2>Agenda</h2>
		</div>
		<div class="col-12">
			<table id="conferenceTable" width="80%">
				<thead>
					<tr>
						<th
							width="10%"
							align="left"
							style="vertical-align: top"
						>
							Day
						</th>
						<th align="left">Date</th>
						<th width="70%" align="left">Session</th>
					</tr>
				</thead>

				<tbody>
					<tr
						v-for="(grp, key, index) in groupedSessions"
						:key="grp.id"
					>
						<td>{{ index + 1 }}</td>
						<td>{{ key }}</td>
						<td>
							<ul>
								<li v-for="ev in grp" :key="ev">
									{{ ev.name }} - {{ getTime(ev.datetime) }}
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</section>
	<AbstractSection v-if="!this.isPast" v-bind:conference="conference.id" />
	<Gallery v-bind:gallery="gallery" />
	<registration-form-modal v-bind:conference="conference.id" />
	<sponsor-registration-form-modal v-bind:conference="conference.id" />
	<AbstractForm v-bind:conference="conference.id" />
</template>

<script>
import AbstractSection from "./abstractComponent.vue";
import AbstractForm from "./modals/abstractForm.vue";
import Gallery from "../../components/gallery.vue";
import registrationFormModal from "./modals/registrationForm.vue";
import sponsorRegistrationFormModal from "./modals/sponsorsForm.vue";
import Api from "../../services";
import getDateRange, { formatDate, formatTime } from "../../helpers/dates";
import _ from "underscore";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
	components: {
		registrationFormModal,
		sponsorRegistrationFormModal,
		AbstractSection,
		Gallery,
		AbstractForm,
		Splide,
		SplideSlide,
	},
	created() {
		this.getConference();
		this.getGallery();
	},
	data() {
		return {
			conference: {
				guests: [],
			},
			gallery: [],
			venue: {},
			options: {
				rewind: true,
				perPage: 1,
				autoplay: true,
				interval: 3000,
				// arrows: false,
			},
			currIndex: 0,
			currCaption: "",
		};
	},
	methods: {
		getConference() {
			this.$store.commit("setLoading", true);
			Api.conferences
				.get(`?slug=${this.$route.params.slug}`)
				.then((res) => {
					this.conference = res.data[0];
				}, this.$store.commit("setLoading", false));
		},
		getGallery() {
			Api.conferenceGalleries
				.get(`?conference.slug=${this.$route.params.slug}`)
				.then((res) => {
					this.gallery = res.data;
				});
		},
		showRegistrationFormModal() {
			let modal = document.getElementById("registrationFormModal");
			modal.style.display = "Block";
		},
		showSponsorsRegistrationFormModal() {
			let modal = document.getElementById("sponsorRegistrationFormModal");
			modal.style.display = "Block";
		},
		getTime(str) {
			return formatTime(str);
		},
		showAbstractForm() {
			let modal = document.getElementById("abstractFormModal");
			modal.style.display = "Block";
		},
		moved(splide) {
			this.currIndex = splide.index;
		},
		onMounted(splide) {
			this.currIndex = splide.index;
		},
	},
	computed: {
		getDates() {
			return getDateRange(
				this.conference.start_date,
				this.conference.end_date
			);
		},
		guests() {
			if (this.conference.conference_guests.length > 0) {
				return true;
			}
			return false;
		},
		groupedSessions() {
			let grps = _.groupBy(this.conference.agenda, (obj) => {
				return formatDate(obj.datetime);
			});
			console.log(grps);
			return grps;
		},
		isPast() {
			var now = new Date();
			var end_date = new Date(this.conference.end_date);

			if (end_date - now > 0) {
				return true;
			} else {
				return false;
			}
		},
	},
};
</script>
