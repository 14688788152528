<template>
  <section class="services" v-if="services.description">
    <div class="">
      <div class="mx-auto text-center">
        <div data-aos="fade-up" data-aos-delay="300">
          <h2>Our Services</h2>
          <p v-html="services?.description" class="pb-3 px-4"></p>
        </div>
        <div class="servicesWrap" data-aos="fade-up" data-aos-delay="400">
          <div class="serviceCard">
            <div class="serviceCardInfo">
              <div class="sci-text">
                <h4 class="mt-3">EQA</h4>
                <p>
                  HuQAS offers EQA Programs relevant to medical laboratories’
                  area of operation e.g. Clinical Chemistry, Blood Screening,
                  Hematology, Parasitology, Bacteriology and much more.
                </p>
                <button
                  class="primary mt-3"
                  @click="this.router.push('/programs')"
                >
                  Read More
                </button>
              </div>
            </div>
            <div class="serviceCardImg">
              <img
                v-if="services.eqa_image !== undefined"
                v-bind:src="services.eqa_image?.url"
                alt=""
              />
            </div>
          </div>

          <div class="serviceCard">
            <div class="serviceCardInfo">
              <div class="sci-text">
                <h4 class="mt-3">CPD Trainings</h4>
                <p>
                  Every year HuQAS undertakes specialized laboratory centered
                  training, that is aimed towards improving laboratory personnel
                  and laboratory testing.
                </p>
                <button
                  class="primary mt-3"
                  @click="this.router.push('/training')"
                >
                  Read More
                </button>
              </div>
            </div>
            <div class="serviceCardImg">
              <img
                v-if="services.training_image !== undefined"
                v-bind:src="services.training_image?.url"
                alt=""
              />
            </div>
          </div>

          <div class="serviceCard">
            <div class="serviceCardInfo">
              <div class="sci-text">
                <h4 class="mt-3">ISO 15189 Mentorship</h4>
                <p>
                  It specifies the particular requirements for quality and
                  competence in medical laboratories. Working within and
                  alongside medical laboratory staff.
                </p>
                <button
                  class="primary mt-3"
                  @click="this.router.push('/mentorship')"
                >
                  Read More
                </button>
              </div>
            </div>
            <div class="serviceCardImg">
              <img
                v-if="services.mentorship_image !== undefined"
                v-bind:src="services.mentorship_image?.url"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Api from "../../../services";
import { useRouter } from "vue-router";

export default {
  created() {
    Api.services
      .get()
      .then((res) => {
        this.services = res.data;
        this.$store.commit("setLandingProgress", {
          key: "services",
          value: true,
        });
      })
      .catch((e) => console.log(e));
  },
  data() {
    return {
      services: {},
      router: useRouter(),
    };
  },
};
</script>

<style lang="scss" scoped>
p {
  max-width: 600px;
  text-align: center;
  margin: 0px auto;
}
</style>
