import Call from "./call";

const Api = () => {
	var setUrl = (path) => {
		return new Call(path);
	};

	var setMailUrl = (path) => {
		return new Call(path, true);
	};

	return {
		server_url: setUrl(),
		ping: setUrl(""),
		training: setUrl("/trainings"),
		programs: setUrl("/programs"),
		quotations: setUrl("/requests"),
		conferenceRegistration: setUrl("/conference-registrations"),
		trainingRegistration: setUrl("/training-registrations"),
		trainingGalleries: setUrl("/training-galleries"),
		conferenceGalleries: setUrl("/conference-galleries"),
		conferenceGuests: setUrl("/conference-guests"),
		conferences: setUrl("/conferences"),
		packages: setUrl("/sponsor-packages"),
		news: setUrl("/news-articles"),
		categories: setUrl("/program-categories"),
		services: setUrl("/service"),
		testimonials: setUrl("/testimonials"),
		statistics: setUrl("/statistics"),
		documents: setUrl("/documents"),
		catalogues: setUrl("/catalogues"),
		newsletter: setUrl("/newsletters"),
		events: setUrl("/events"),
		contactform: setUrl("/contactforms"),
		about: setUrl("/about"),
		mentorship: setUrl("/mentorship"),
		analytics: setUrl("/user-analytics"),
		abstracts: setUrl("/abstracts"),
		headlines: setUrl("/headlines"),
		sendemail: setMailUrl("/sendemail"),
		workshopRegistration: setUrl("/workshop-registrations"),
	};
};

export default Api();
