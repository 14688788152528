<template>
	<div class="post mb-5" v-if="training.slug !== this.$route.params.slug">
		<div class="postImage">
			<img :src="training.visual.url" alt="" />
		</div>

		<div class="postText">
			<router-link v-bind:to="'/training/' + training.slug">
				<h3>{{ training.title }}</h3>
			</router-link>
			<span class="postDates">
				<h4>{{ getDates }}</h4>
			</span>
			<p className="my-4">
				{{ truncated(training.description, 200) }}
			</p>
		</div>
	</div>
</template>

<script>
import getDateRange from "../../helpers/dates";
import { truncate } from "../../helpers/truncate";

export default {
	props: ["training"],
	computed: {
		getDates() {
			return getDateRange(
				this.training.start_date,
				this.training.end_date
			);
		},
	},
	methods: {
		truncated(str, int) {
			return truncate(str, int);
		},
	},
};
</script>
