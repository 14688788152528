import { createStore } from "vuex";
import quotationStore from "../pages/eqa/quotation/store";

const Store = createStore({
  state: {
    loading: true,
    landing_page_progress: {
      services: false,
      calendar: false,
      testimonials: false,
      statistics: false,
      downloads: false,
      latest: false,
    },
    media_url: process.env.VUE_APP_API_URL,
    progress: 0,
  },
  mutations: {
    setLoading(state, n) {
      state.loading = n;
    },
    setProgress(state, n) {
      state.progress = n;
    },
    setLandingProgress(state, obj) {
      state.landing_page_progress[obj.key] = obj.value;
      var size = Object.keys(state.landing_page_progress).length;
      var p = 0;
      Object.values(state.landing_page_progress).forEach((val) => {
        if (val) {
          p++;
        }
      });
      state.progress = (p / size) * 100;

      if (state.progress == 100) {
        state.loading = false;
      }
    },
  },
  getters: {
    media_url: (state) => {
      return state.media_url;
    },
    loading: (state) => {
      return state.loading;
    },
    progress: (state) => {
      return state.progress;
    },
  },
  modules: {
    quotations: quotationStore,
  },
});

export default Store;
