<template>
  <div class="col-11">
    <div class="col-12 d-flex flex-wrap">
      <div class="contactgroup">
        <div class="contactIcon" style="transform: rotate(5deg)">
          <i class="fas fa-paper-plane"></i>
        </div>
        <div class="contactInfo my-4">
          <p>info@huqas.org</p>
          <p>huqas@huqas.org</p>
        </div>
      </div>
      <div class="contactgroup">
        <div class="contactIcon" style="transform: rotate(-8deg)">
          <i class="fas fa-phone"></i>
        </div>
        <div class="contactInfo my-4">
          <p>
            +254 722 800 971 <br />
            +254 716 842 068 <br />
            +254 20 354 2483
          </p>
        </div>
      </div>
      <div class="contactgroup">
        <div class="contactIcon" style="transform: rotate(10deg)">
          <i class="fas fa-map-marker-alt"></i>
        </div>
        <div class="contactInfo my-4">
          <p>
            Human House 1st Floor, <br />
            Ngara West Road, <br />
            Nairobi, Kenya
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
