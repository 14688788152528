<script setup></script>

<template>
	<div class="videoSection">
		<div class="videoSectionWrap">
			<div class="videoInfo" data-aos="fade-right" data-aos-delay="300">
				<h2>HuQAS EQA User Guide</h2>
				<p>
					Watch the following tutorials on how to access and use the
					HuQAS EQA website.
				</p>
			</div>
			<div class="videoEmbed" data-aos="fade-left" data-aos-delay="300">
				<iframe
					src="https://www.youtube.com/embed/videoseries?list=PL8yIVvYTnOg3fIvKlkgagHyCieAb_V15K"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
			</div>
		</div>
	</div>
</template>
