<template>
  <div class="topbar">
    <div class="topbarContacts">
      <span>
        <a href="mailto:info@huqas.org">
          <i class="fas fa-envelope"></i>
          info@huqas.org
        </a>
      </span>

      <span>
        <i class="fas fa-phone"></i>
        +254 20 354 2483
      </span>
    </div>
    <div class="socials">
      <span>Follow us:</span>
      <span class="icons secondary">
        <a
          target="_blank"
          href="https://www.linkedin.com/company/human-quality-assessment-services-huqas/about/"
          ><i class="fab fa-linkedin"></i></a
      ></span>
      <span class="icons secondary">
        <a target="_blank" href="https://twitter.com/huqas"
          ><i class="fab fa-twitter"></i></a
      ></span>
      <span class="icons secondary">
        <a target="_blank" href="https://instagram.com/huqas_eqa"
          ><i class="fab fa-instagram"></i> </a
      ></span>
      <span class="icons secondary">
        <a target="_blank" href="https://facebook.com/huqas"
          ><i class="fab fa-facebook"></i></a
      ></span>
      <span class="icons secondary">
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UC8DS95MUF1G4QACxFZiuqfg/featured"
          ><i class="fab fa-youtube"></i></a
      ></span>
    </div>
  </div>
</template>
