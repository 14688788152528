<template>
  <section
    class="carousel d-flex justify-content-center"
    v-if="this.mentorship.title"
  >
    <div class="row justify-content-around slide">
      <div class="slide-info">
        <div>
          <h1>{{ mentorship.title }}</h1>
          <p>{{ mentorship.about }}</p>
        </div>
        <router-link to="/mentorship/quote">
          <button class="primary">Request Quote</button>
        </router-link>
      </div>
      <div class="slide-shadow"></div>
      <div class="slide-img">
        <div class="carousel-img-wrap">
          <img
            src="https://huqasstrapimedia.s3.us-east-2.amazonaws.com/Whats_App_Image_2022_03_21_at_6_12_56_PM_4f8595ea55.jpeg"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>

  <section
    class="d-flex justify-content-center pt-5"
    v-if="this.mentorship.title"
  >
    <div class="col-10">
      <div class="d-flex justify-content-center flex-wrap">
        <div
          class="col-11 col-sm-10 col-md-8 col-lg-5"
          v-html="mentorship.description"
        ></div>

        <div class="col-12 col-sm-10 col-md-8 col-lg-5">
          <div class="text-center">
            <img :src="mentorship.image.url" alt="" class="w-100" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
li {
  position: relative;
  &::before {
    content: "\f0da";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    font-size: 20px;
    position: absolute;
    left: -20px;
  }
}
</style>

<script>
import Api from "../../services/index";

export default {
  data() {
    return {
      mentorship: {},
    };
  },
  created() {
    Api.mentorship
      .get()
      .then((res) => {
        this.mentorship = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>
