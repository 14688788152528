<template>
  <section class="row col-10 py-5 justify-content-around">
    <h3 class="text-center">Talk With Us</h3>
    <div class="col-10 col-lg-7">
      <contact-form></contact-form>
    </div>

    <div class="col-10 col-lg-3 my-5">
      <contacts></contacts>
    </div>
  </section>
  <Mapp />
</template>

<script>
import ContactForm from "./contact-form.vue";
import Contacts from "./contacts.vue";
import Mapp from "./map.vue";

export default {
  components: { ContactForm, Contacts, Mapp },
};
</script>
