<template>
  <section class="row py-5" v-if="this.loading === false && abstracts[0]">
    <div class="col-12">
      <h2 class="text-center">Featured Abstracts</h2>
    </div>
    <div class="col-12 d-flex flex-wrap">
      <div
        v-for="abstract in abstracts"
        :key="abstract.id"
        class="post mx-5 col-11 col-sm-6 col-md-5 mb-5"
      >
        <div class="postImage">
          <img :src="abstract.image.url" v-if="abstract.image" alt="" />
        </div>

        <div class="postText">
          <router-link :to="`/conference/abstract/${abstract.slug}`">
            <h3>{{ abstract.title }}</h3>
          </router-link>

          <span class="postDates">
            <h4>
              by
              {{
                `${abstract.authors[0].first_name} ${abstract.authors[0].last_name}`
              }}
              <span v-if="abstract.authors.length > 1">and others</span>
            </h4>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Api from "../../services";

export default {
  props: ["conference"],
  created() {
    this.getAbstracts();
  },
  data() {
    return {
      abstracts: [],
      loading: true,
    };
  },
  methods: {
    getAbstracts() {
      Api.abstracts
        .get(`?conference.slug=${this.$route.params.slug}`)
        .then((res) => {
          this.abstracts = res.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
