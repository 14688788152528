<template>
  <div class="programSlide py-4">
    <div class="slideImg">
      <div>
        <img src="" alt="" />
      </div>
    </div>
    <div class="slideText">
      <h3 class="text-white">{{ slide.name }}</h3>
      <ul>
        <li v-for="pg in slide.programs" :key="pg.name">
          {{pg.name}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["slide"],
};
</script>
