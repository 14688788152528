<template>
  <div class="text-center">
    <h2>Mentorship Quotation Request</h2>
    <p>Please fill in your details.</p>
  </div>
  <form @submit="handleForm($event)" id="quotationForm" class="py-5">
    <text-input
      v-model="user.organization"
      label="Organization"
      placeholder="Organization"
      required="true"
      class="halfGroup"
    />

    <text-input
      v-model="user.title"
      placeholder="Position/Title"
      required="true"
      class="halfGroup"
      label="Title"
    />

    <text-input
      v-model="user.first_name"
      placeholder="First Name"
      required="true"
      class="halfGroup"
      label="First Name"
    />

    <text-input
      v-model="user.last_name"
      label="Last Name"
      placeholder="Last Name"
      required="true"
      class="halfGroup"
    />

    <text-input
      v-model="user.email"
      placeholder="Email Address"
      required="true"
      class="halfGroup"
      label="Email Address"
    />

    <text-input
      v-model="user.phone_number"
      placeholder="Phone Number"
      required="true"
      class="halfGroup"
      label="Phone Number"
    />

    <text-input
      v-model="user.town"
      placeholder="City"
      required="true"
      class="halfGroup"
      label="City"
    />

    <text-input
      v-model="user.country"
      placeholder="Phone Number"
      required="true"
      class="halfGroup"
      label="Country"
    />

    <div class="inputGroup text-center">
      <button class="secondary" id="submitBtn">
        Send Request &nbsp;
        <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </form>
</template>

<script>
import Api from "../../services";
import TextInput from "../../components/inputs/textInput";

export default {
  components: { TextInput },
  created() {
    TextInput;
  },
  data() {
    return {
      user: {
        organization: "",
        title: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        town: "",
        country: "",
        type: "mentorship",
      },
      formSent: false,
    };
  },
  methods: {
    sendEmail(data) {
      Api.sendemail
        .post({ ...data, template: "mentorship" })
        .then()
        .catch((e) => console.log(e));
    },
    handleForm(e) {
      e.preventDefault();
      var btn = document.getElementById("submitBtn");
      btn.innerHTML = "Sending <i class='fas fa-spinner fa-spin'></>";

      Api.quotations
        .post(this.user)
        .then((res) => {
          this.sendEmail(res.data);
          btn.innerHTML = "Sent! <i class='fas fa-check'></i>";
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>
