<template>
  <section class="py-5 bg_primary">
    <div class="row text-white">
      <div class="col-12">
        <h3 class="text-white text-center">Programs We Offer</h3>
      </div>
      <div class="col-12 py-5" id="programCarousel">
        <div id="programCarouselSlider">
          <Splide :options="options" :slides="categories">
            <SplideSlide v-for="cat in categories" :key="cat.name">
              <programs-component v-bind:slide="cat" />
            </SplideSlide>
          </Splide>
        </div>
      </div>
      <div class="col-12 py-5 text-center">
        <router-link to="/programs/quotation/programs">
          <button class="primary">Request For Quote</button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import programsComponent from "./components/programsComponent.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import Api from "../../services";

export default {
  components: { programsComponent, Splide, SplideSlide },
  created() {
    this.getCategories();
  },
  data() {
    return {
      categories: [],
      options: {
        rewind: true,
        width: 1400,
        perPage: 2,
        gap: "1rem",
        autoplay: true,
        breakpoints: {
          1300: {
            perPage: 1,
          },
        },
      },
    };
  },
  methods: {
    getCategories() {
      this.$store.commit("setLoading", true);
      Api.categories
        .get()
        .then((res) => {
          this.categories = res.data;
        }, this.$store.commit("setLoading", false))
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss">
button.splide__arrow {
  background: none;
  color: white;
}
</style>
