<template>
  <section class="carousel d-flex justify-content-center">
    <div class="row justify-content-around slide" v-if="conference.title">
      <div class="slide-info">
        <div>
          <h1 class="mb-2">{{ conference.title }}</h1>
          <h3 class="">Theme: {{ conference.theme }}</h3>
          <h4 class="mb-0">{{ getDates }}</h4>
          <p v-if="conference.description">
            {{ truncated }}
          </p>
        </div>
        <router-link :to="`/conference/${conference.slug}`">
          <button class="primary">Read More</button>
        </router-link>
      </div>
      <div class="slide-shadow"></div>
      <div class="slide-img">
        <img :src="conference.visual?.url" alt="" />
      </div>
    </div>
  </section>

  <!-- <div class="row col-10 py-5 justify-content-around">
    <div class="col-11">
      <h3>Write & Submit Your Own Abstract</h3>
      <p class="col-10 col-md-6">
        Prepare and submit an abstract for consideration in the upcoming HuQAS
        Scientific Conference. Pick a subtheme from the list below as a subject
        for your abstract. We look forward to your submission.
      </p>

      <h3>Sub themes</h3>
      <div v-html="conference.subthemes"></div>

      <button class="secondary my-4" @click="showAbstractForm">
        Submit Your Abstract &nbsp; <i class="fas fa-file-alt"></i>
      </button>
    </div>
  </div> -->

  <PastConferences v-bind:conferences="conferences" />
  <AbstractForm v-bind:conference="conference.id" />
</template>

<script>
import AbstractForm from "./modals/abstractForm.vue";

import PastConferences from "./pastConferences.vue";
import Api from "../../services";
import getDateRange from "../../helpers/dates";

export default {
  components: { AbstractForm, PastConferences },
  created() {
    this.getConference();
  },
  data() {
    return {
      conference: {},
      conferences: [],
    };
  },
  methods: {
    getConference() {
      this.$store.commit("setLoading", true);
      Api.conferences.get("?_sort=start_date:desc").then((res) => {
        var cons = res.data;
        this.conference = cons[0];
        cons.shift();
        this.conferences = cons;
      }, this.$store.commit("setLoading", false));
    },
    showAbstractForm() {
      let modal = document.getElementById("abstractFormModal");
      modal.style.display = "Block";
    },
  },
  computed: {
    getDates() {
      return getDateRange(this.conference.start_date, this.conference.end_date);
    },
    truncated() {
      var str = this.conference.description;
      if (str.length > 1) {
        return str.slice(0, 180) + " ...";
      }
      return "";
    },
  },
};
</script>
