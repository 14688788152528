<template>
	<header
		:class="`header ${this.checkRoute ? 'shortHeader' : ''}`"
		id="header"
	>
		<Countdown />
		<div class="header-wrap">
			<div class="navbars">
				<span class="icons" @click="showMobileMenu()">
					<i class="fas fa-bars fa-lg"></i>
				</span>
			</div>
			<div class="logo">
				<router-link to="/">
					<img src="../../assets/img/logo.png" alt="" width="150px" />
				</router-link>
			</div>

			<nav class="nav main-nav">
				<span>
					<div class="dropdown" @click="showDropdown()">
						<a>
							EQA Login
							<span class="icons">
								<i class="fas fa-caret-down"></i>
							</span>
						</a>
						<div class="dropdown-menu" id="dropdown-menu">
							<a href="https://eqa.huqas.org/" target="_blank"
								>Quarterly EQA</a
							>
							<a
								href="https://monthly-eqa.huqas.org/"
								target="_blank"
								>Monthly EQA</a
							>
						</div>
					</div>
				</span>
				<!-- <span><router-link to="/news">News</router-link></span> -->
				<span><router-link to="/programs">Programs</router-link></span>
				<span><router-link to="/training">Training</router-link></span>
				<span
					><router-link to="/mentorship"
						>Mentorship</router-link
					></span
				>
				<span
					><router-link to="/conference"
						>Conference</router-link
					></span
				>

				<span><router-link to="/about">About Us</router-link></span>
				<span
					><router-link to="/contact-us"
						>Contact Us</router-link
					></span
				>
			</nav>

			<mobile-nav />
		</div>
	</header>
</template>

<script>
import MobileNav from "./mobileNav";
import Countdown from "../../components/countdown/index.vue";
export default {
	components: {
		MobileNav,
		Countdown,
	},
	name: "HeaderComponent",
	created() {
		this.handleScroll();
		this.mobileNavShowing = false;
	},
	data() {
		return {
			mobile: false,
			mobileNavShowing: false,
		};
	},
	watch: {
		checkRoute() {
			this.handleScroll();
		},
	},
	methods: {
		showMobileMenu() {
			var menu = document.getElementById("mobile-nav");
			if (menu) {
				if (menu.style.left == "0px") {
					menu.style.left = "-100vw";
				} else {
					menu.style.left = "0px";
				}
			}
		},
		showDropdown() {
			var drop = document.getElementById("dropdown-menu");
			if (drop) {
				console.log();
				if (drop.style.height == "") {
					drop.style.height = "auto";
				} else {
					drop.style.height = "";
				}
			}
		},
		handleScroll() {
			document.addEventListener("scroll", () => {
				var header = document.getElementById("header");
				if (header) {
					if (window.scrollY > 150) {
						header.classList.add("shortHeader");
					} else {
						if (!this.checkRoute) {
							header.classList.remove("shortHeader");
						}
					}
				}
			});
		},
	},
	computed: {
		checkRoute() {
			if (this.$route.path.split("/").length >= 3) {
				return true;
			} else {
				return false;
			}
			// console.log(this.$route.path)
			// return false
		},
	},
};
</script>
