<template>
  <section class="carousel d-flex justify-content-center">
    <div class="row justify-content-around slide">
      <div class="slide-info">
        <div id="aboutSection">
          <h1>About HuQAS</h1>
          <p>
            HuQAS (Human Quality Assessment Services) is a Non-Governmental
            Organization (NGO) that has been offering Proficiency Testing
            Services (EQA) and related Technical training to Public and Private
            Medical Laboratories since the year 2000.
          </p>
          <a href="#intro">
            <button class="primary">Read More</button>
          </a>
        </div>
        <!-- <button class="primary">Read More</button> -->
      </div>
      <div class="slide-shadow"></div>
      <div class="slide-img">
        <img src="../../assets/img/huqas_office.jpg" alt="" />
      </div>
    </div>
  </section>

  <section class="d-flex justify-content-center py-5">
    <div class="col-10">
      <!-- <h2 class="text-center">The Mentorship Journey to Accreditation.</h2> -->
      <div class="d-flex justify-content-center flex-wrap">
        <div class="col-11 col-sm-10 col-md-8 col-lg-5">
          <h2 class="mb-0" id="intro">Introduction</h2>
          <article v-html="about.intro"></article>
        </div>

        <div class="col-11 col-sm-10 col-md-8 col-lg-5">
          <div class="pb-4">
            <img src="../../assets/img/africa.svg" alt="" class="w-100" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <Statistics />
</template>

<script>
import Statistics from "../home/components/statistics.vue";
import Api from "../../services/index";
export default {
  components: { Statistics },
  data() {
    return {
      about: {},
    };
  },
  created() {
    this.getAbout();
  },
  methods: {
    getAbout() {
      Api.about.get().then((res) => (this.about = res.data));
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  position: relative;
  &::before {
    content: "\f0da";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    font-size: 20px;
    position: absolute;
    left: -20px;
  }
}
</style>
