<template>
  <section class="row justify-content-between article-container">
    <div class="col-11 col-sm-10 col-lg-7">
      <h2 class="mb-2">{{ article.title }}</h2>
      <div class="mb-3">
        <span class="mx-1">
          {{ getDates }}
        </span>
        <span> &middot; </span>
        <span class="mx-1"> {{ getReadTime }} min read </span>
        <!-- <span> &middot; </span> -->
        <!-- <span
          >by {{ article.author?.first_name }}
          {{ article.author?.last_name }}</span
        > -->
      </div>
      <div>
        <div class="ImageWrap">
          <img v-if="article.thumbnail" :src="article.thumbnail.url" alt="" />
        </div>

        <p class="article_subtitle">
          {{ article.description }}
        </p>
      </div>

      <article v-html="article.article"></article>

      <div class="my-5">
        <div class="mx-auto">
          <div id="disqus_thread"></div>
        </div>
      </div>
    </div>

    <div class="col-11 col-sm-10 col-lg-4">
      <h3>More News</h3>
      <div class="d-flex flex-wrap py-3" v-if="news.length > 0">
        <NewsComponent
          v-for="item in news"
          v-bind:key="item.id"
          v-bind:article="item"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Api from "../../services";
import { formatDate } from "../../helpers/dates";
import { AddMetaTags } from "../../helpers/metatags";
import { getReadTime } from "../../helpers/readtime";
import { disqus } from "../../services/disqus";
import NewsComponent from "./newsComponent.vue";

export default {
  components: { NewsComponent },
  props: ["params"],
  created() {
    this.getData();
    this.getNews();
    disqus();
  },
  watch: {
    params() {
      this.getData();
    },
  },
  data() {
    return {
      article: {},
      news: [],
    };
  },
  methods: {
    getData() {
      this.$store.commit("setLoading", true);
      Api.news.get(`?slug=${this.$route.params.slug}`).then((res) => {
        this.article = res.data[0];
        AddMetaTags({
          title: this.article.title,
          image: this.article.thumbnail.url,
          description: this.article.description,
        });
      }, this.$store.commit("setLoading", false));
    },
    getNews() {
      this.$store.commit("setLoading", true);
      Api.news
        .get(`?_sort=id:asc&_limit=10`)
        .then(
          (res) => (this.news = res.data),
          this.$store.commit("setLoading", false)
        )
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    getDates() {
      return `${formatDate(this.article.date)}`;
    },
    getReadTime() {
      return getReadTime(this.article.article);
    },
    linkedinsharelink() {
      return `https://linkedin.com/shareArticle?url=https://alpha.huqas.org/news/article/upcoming-scientific-conference-in-kampala-uganda&title=${this.article.title}`;
    },
  },
};
</script>
