<template>
  <div class="col-12 col-lg-3 col-md-4 calendar-event p-5">
    <div class="calendar-event-month">
      <span>
        <i class="fas fa-caret-right"></i>
        {{ events[ev].event_type?.name }}
      </span>
    </div>
    <div class="calendar-event-details pt-5">
      <div class="calendar-event-date py-3">
        <span>
          <i class="fas fa-calendar-alt"></i>
          {{ events[ev].dates }}
        </span>
      </div>
      <div class="calendar-event-info py-3">
        <span> {{ events[ev].title }} </span>
      </div>
    </div>
    <div class="calendar-cta">
      <button
        class="primary"
        v-if="events[ev].event_type.name === 'Training'"
        @click="goToTraining"
      >
        Register
      </button>
      <button
        class="primary"
        v-if="events[ev].event_type.name === 'Quarterly Test Event'"
        @click="goToQuarterly"
      >
        Go to EQA
      </button>

      <button
        class="primary"
        v-if="events[ev].event_type.name === 'Monthly Test Event'"
        @click="goToMonthly"
      >
        Go to EQA
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["events", "ev"],
  methods: {
    goToQuarterly() {
      window.open("https://eqa.huqas.org/", "_blank");
    },
    goToMonthly() {
      window.open("https://monthly-eqa.huqas.org/", "_blank");
    },
    goToTraining() {
      this.$router.push("/training/");
    },
  },
};
</script>
