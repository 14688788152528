<script setup>
import { onMounted, reactive, ref } from "vue";
import count from "../../helpers/timer";
import WorkshopForm from "./form.vue";

const ct = reactive({
  timer: {},
});

const handleform = ref(null);

const toggleModal = () => {
  handleform.value?.toggleModal();
};

onMounted(() => {
  setInterval(() => {
    ct.timer = count(new Date("2023-07-28T08:00"));
  }, 1000);
});
</script>

<template>
  <WorkshopForm ref="handleform" />
  <div class="container workshop-container px-3">
    <div class="row d-flex justify-content-around">
      <section class="workshopDetails col-11 pt-5">
        <div>
          <img src="../../assets/img/logo.svg" alt="" width="150px" />
        </div>

        <h1 class="mt-2">TANZANIA WORKSHOP</h1>

        <div class="my-4">
          <h4>Theme:</h4>
          <h3>
            Navigating Quality Management in Medical Laboratories: Process
            Control and Effective Utilization of HuQAS EQA.
          </h3>
        </div>

        <div class="my-4">
          <h4>Objectives:</h4>
          <ol>
            <li>
              To Promote the Implementation of Quality Management Systems in
              Medical Laboratories.
            </li>
            <li>To Facilitate Effective Utilization of EQA Services.</li>
            <li>Address Challenges in EQA Implementation</li>
            <li>
              Strengthen Collaboration among Healthcare Provider Stakeholders
            </li>
          </ol>
        </div>

        <div class="my-5">
          <div class="row">
            <div class="col-4 text-center">
              <span>
                <i class="fas fa-calendar-alt fa-2x"></i>
              </span>
              <p>
                July 28, <br />
                2023
              </p>
            </div>
            <div class="col-4 text-center">
              <span>
                <i class="fas fa-map-marker-alt fa-2x"></i>
              </span>
              <p>
                Holiday Inn <br />
                Dar es Salaam, <br />
                TZ
              </p>
            </div>
            <div class="col-4 text-center">
              <span>
                <i class="fas fa-ticket-alt fa-2x"></i>
              </span>
              <p>TZS 35,000</p>
            </div>
          </div>
        </div>
      </section>

      <section class="col-11 my-5 flex-grow">
        <div id="rightCol" class="flex-grow">
          <h3 class="text-center">Book Your Slot Now.</h3>
          <div class="countdown">
            <div class="counter text-center">
              <span> {{ ct.timer.days }} </span>
              <p>Days</p>
            </div>

            <div class="counter text-center">
              <span> {{ ct.timer.hours }} </span>
              <p>Hours</p>
            </div>

            <div class="counter text-center">
              <span> {{ ct.timer.minutes }} </span>
              <p>Min</p>
            </div>

            <div class="counter text-center">
              <span> {{ ct.timer.seconds }} </span>
              <p>Sec</p>
            </div>
          </div>
          <div class="text-center my-4">
            <button class="secondary" @click="toggleModal">Register</button>
          </div>

          <div id="flags">
            <img src="../../assets/img/tz_huqas.png" alt="" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
