<template>
	<Topbar v-if="timer.days < 0"></Topbar>
	<div id="countdownWrap" v-if="timer.days >= 0">
		<div id="countdownInfo">
			<div id="countdownText">
				<p>{{ event.title }}</p>
			</div>

			<div id="countdownTimer">
				<div class="countdownTimerWrap">
					<div class="countdownTickers">
						<p>
							{{ timer.days || "00" }}
							<span>Day{{ timer.days > 1 ? "s" : "" }}</span>
						</p>
					</div>
					<div class="countdownTickers">
						<p>
							{{ timer.hours || "00" }}
							<span>Hour{{ timer.hours > 1 ? "s" : "" }}</span>
						</p>
					</div>
					<div class="countdownTickers">
						<p>
							{{ timer.minutes || "00" }}
							<span
								>Minute{{ timer.minutes > 1 ? "s" : "" }}</span
							>
						</p>
					</div>
					<div class="countdownTickers">
						<p>
							{{ timer.seconds || "00" }}
							<span
								>Second{{ timer.seconds > 1 ? "s" : "" }}</span
							>
						</p>
					</div>
				</div>
			</div>

			<div id="ctaBtn">
				<router-link
					:to="`${
						event.event_type.name == 'Conference'
							? '/conference'
							: '/training'
					}`"
				>
					<p>Register Here <i class="fas fa-arrow-right"></i></p>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import count from "../../helpers/timer";
import Api from "../../services";
import { getToday } from "../../helpers/dates";
import Topbar from "../header/topbar.vue";

export default {
	created() {
		this.counter();
	},
	data() {
		return {
			timer: {},
			event: {},
		};
	},
	methods: {
		counter() {
			Api.events
				.get(
					`?event_type.name=Training&event_type.name=Conference&_sort=start_date:asc&start_date_gte=${getToday()}&_limit=1`
				)
				.then((res) => {
					this.event = res.data[0];
					if (res.data[0].start_date) {
						setInterval(() => {
							this.timer = count(res.data[0]?.start_date);
						}, 1000);
					}
				});
		},
	},
	components: { Topbar },
};
</script>
