<template>
  <Carousel />
  <Services />
  <VideoSection />
  <Calendar />
  <Downloads />
  <!-- <Statistics /> -->
  <!-- <Testimonials></Testimonials> -->
</template>

<script lang="js">
import Carousel from "./components/carousel/carousel.vue";
import Services from "./components/services";
import VideoSection from "./components/video.vue";
import Calendar from "../../components/calendar";
import Downloads from "../../components/downloads";
// import Statistics from "./components/statistics";
// import Testimonials from "../../components/testimonials";

export default {
  components: {
    Carousel,
    Services,
    VideoSection,
    Calendar,
    Downloads,
    // Statistics,
    // Testimonials,
  },
  beforeCreate() {},
  created() {},
  data() {
    return {
      trainings: [],
    };
  },
  methods: {
    showLoader() {
      this.$store.commit("setLoading", true);
    },
  },
};
</script>
