<template>
  <router-view></router-view>
</template>

<script>
import AOS from "aos";
import getAnalytics from "./helpers/analytics";
import * as Cronitor from "@cronitorio/cronitor-rum-js";

export default {
  name: "App",
  mounted() {
    AOS.init({
      easing: "ease",
      duration: 1000,
      delay: 200,
    });
    Cronitor.load("30cd529587dc4969c28fc3dca7e7813b");
    Cronitor.track("Pageview");

    if (!sessionStorage.getItem("visited")) {
      getAnalytics();
    }
  },
};
</script>
