import AbstractDetails from "./abstractDetails.vue";
import ConferenceHome from "./conference.vue";
import ConferenceDetails from "./conferenceDetails.vue";

const ConferenceRoutes = [
	{
		path: "/conference",
		component: ConferenceHome,
	},
	{
		path: "abstract/:slug",
		component: AbstractDetails,
	},
	{
		path: "/conference/:slug",
		component: ConferenceDetails,
	},
];

export default ConferenceRoutes;
