<template>
	<div class="container-fluid p-0" id="mainContainer">
		<div
			v-if="this.server === true && this.$store.getters.loading === false"
		>
			<HeaderComponent></HeaderComponent>
			<div id="mainContentWrap">
				<router-view></router-view>
			</div>
			<FooterComponent></FooterComponent>
			<ChatComponent />
		</div>
		<LoadingComponent
			v-if="this.$store.getters.loading"
			:progress="this.$store.getters.progress"
		/>
		<error-500 v-if="this.server === false"></error-500>
	</div>
</template>

<script>
import HeaderComponent from "../../components/header/header.vue";
import FooterComponent from "../../components/footer";
import LoadingComponent from "../../components/loading";
import ChatComponent from "../../components/chat";
import Error500 from "../errors/error_500.vue";
import Api from "../../services";

export default {
	components: {
		HeaderComponent,
		FooterComponent,
		LoadingComponent,
		ChatComponent,
		Error500,
	},
	created() {
		this.pingServer();
	},
	data() {
		return {
			server: false,
		};
	},
	methods: {
		pingServer() {
			this.$store.commit("setLoading", true);
			// if the api server is up, the loading animation will stop displaying
			Api.ping
				.get()
				.then((res) => {
					if (res.status === 200) {
						this.server = true;
						this.$store.commit("setLoading", false);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
</script>
