<template>
	<nav class="mobile-nav" id="mobile-nav">
		<span @click="showMobileMenu"
			><a href="https://eqa.huqas.org/" target="_blank"
				>Quarterly EQA</a
			></span
		>
		<span @click="showMobileMenu"
			><a href="https://monthly-eqa.huqas.org/" target="_blank"
				>Monthly EQA</a
			></span
		>
		<span @click="showMobileMenu"
			><router-link to="/programs">Programs</router-link></span
		>
		<span @click="showMobileMenu"
			><router-link to="/training">Training</router-link></span
		>
		<span @click="showMobileMenu"
			><router-link to="/mentorship">Mentorship</router-link></span
		>
		<span @click="showMobileMenu"
			><router-link to="/conference">Conference</router-link></span
		>
		<!-- <span @click="showMobileMenu"
          ><router-link to="/news">News</router-link></span
        > -->
		<span @click="showMobileMenu"
			><router-link to="/about">About Us</router-link></span
		>
		<span @click="showMobileMenu"
			><router-link to="/contact-us">Contact Us</router-link></span
		>
	</nav>
</template>

<script>
export default {
	methods: {
		// This is not DRY. Will be fixed later with composition API.
		showMobileMenu() {
			var menu = document.getElementById("mobile-nav");
			if (menu) {
				if (menu.style.left == "0px") {
					menu.style.left = "-100vw";
					return;
				}
				menu.style.left = "0px";
				console.log(menu.style.left);
			}
		},
	},
};
</script>
