<template>
  <section class="mapSection">
    <h2 class="text-center">Pay Us a Visit</h2>
    <div class="col-12">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2052.856514138714!2d36.82036438340133!3d-1.2744313067260897!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x64f9eb64facd4f63!2sHuman%20Quality%20Assessment%20Services!5e0!3m2!1sen!2ske!4v1636716961652!5m2!1sen!2ske"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </section>
</template>
